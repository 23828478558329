import type { StatControllerListResponse } from 'api/stat/Stat.schema.ts';
import { GET, isGranted } from 'common/helpers.ts';
import useSWR from 'swr';
import { IBell, IClock, IDoneCircle, ITruck } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { KPI } from '#admin/component/KPI.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

export const Home = () => {
  const me = useMe();

  const { data: stats, error } = useSWR<StatControllerListResponse>(me ? ['/stat/list'] : null, GET);

  if (error) return <div>Error fetching KPIs: {error.message}</div>;
  if (!me || !stats) return <Spinner centered={true} />;

  return (
    <main className="container m-auto flex w-full flex-col">
      <div className="-z-30 absolute inset-0 bg-blue-light" />

      <div className="m-auto grid w-full grid-cols-[repeat(auto-fit,390px)] justify-center gap-4">
        <h1 className="mt-6 mb-2">Overview</h1>

        <KPI
          className="col-start-1"
          color="blue"
          title={'Active shipments'}
          value={stats.TRANSITING_SHIPMENTS}
          Icon={ITruck}
          targetName={'Shipments'}
          targetURL={'/shipments?status$in=pending&status$in=transiting'}
        />
        <KPI
          color="red"
          title={'Delayed shipments'}
          value={stats.DELAYED_SHIPMENTS}
          Icon={IClock}
          targetName={'Shipments'}
          targetURL={'/shipments?delayed=true'}
          description='Count of shipments which generated a "delayed" alert in the last 30d.'
        />
        {isGranted(me, 'ViewAdminLayout') && (
          <KPI
            color="red"
            title={'Active tracker alerts'}
            value={stats.UNARCHIVED_ALERTS}
            Icon={IBell}
            targetName={'Shipments'}
            targetURL={'/alerts?archived=false'}
            description="Count of alerts emitted by the tracker provider, but not read yet."
          />
        )}
        <KPI
          color="green"
          title={'Delivered shipments'}
          value={stats.DELIVERED_SHIPMENTS_LAST_30D}
          Icon={IDoneCircle}
          targetName={'Shipments'}
          targetURL={'/shipments?status=delivered'}
          description="Count of shipments delivered in the last 30d."
        />
      </div>
    </main>
  );
};
