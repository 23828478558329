import type React from 'react';
import { Button } from 'ui/component/Button.tsx';
import { IArrowBack, ICancel } from 'ui/component/Icons.tsx';
import type { VerificationResult } from '#admin/hook/useVerifyDocument.ts';
import { VerificationCard } from './VerificationCard.tsx';

type Props = {
  verificationResult: VerificationResult;
  onBack: () => void;
};

export const DocumentNotVerified: React.FC<Props> = ({ verificationResult, onBack }) => (
  <div className="flex w-full max-w-[55rem] flex-col items-center justify-center gap-8 p-8">
    <div className="flex size-[9.25rem] flex-row items-center justify-center rounded-full bg-green-light p-6">
      <ICancel className="size-full text-red" />
    </div>
    <h1 className="text-grey-body">Document is not verified</h1>
    <VerificationCard document={verificationResult} />
    <div className="flex w-full flex-row" onClick={onBack}>
      <Button className="blue-outlined !w-fit" LeftIcon={IArrowBack}>
        Back
      </Button>
    </div>
  </div>
);
