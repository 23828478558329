import type { ColumnDef, SortingState } from '@tanstack/react-table';
import axios from 'axios';
import cx from 'clsx';
import { dateToLocalYMDHM } from 'common/date.js';
import { POST, emptyToUndefined, fromQuerystring, toQuerystring } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { Role } from 'types/Role.ts';
import { Button } from 'ui/component/Button.tsx';
import { IAdd, IDelete, ISearch } from 'ui/component/Icons.tsx';
import { Input } from 'ui/control/Input.tsx';
import { RoleForm } from '#admin/component/RoleForm.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

export const getColumns = (onDelete: (permission: Role) => void) => {
  const columns: ColumnDef<Role>[] = [
    {
      accessorKey: 'name',
      header: 'Name',
      size: 220,
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';
        const id = info.row.original.id;
        return (
          <Link className="anchor anchor-novisit flex flex-row items-center gap-1" to={`/role/${id}`} data-table-no-row-events>
            {value}
          </Link>
        );
      },
    },
    {
      accessorKey: 'created_at',
      header: 'Created At',
      size: 225,
      cell: (info) => {
        const { created_at } = info.row.original;
        return dateToLocalYMDHM(created_at);
      },
    },
    /*
    {
      accessorKey: 'description',
      header: 'Description',
      size: 350,
    },
    {
      header: 'Users Assigned',
    },
     */
    {
      header: 'Permissions',
      cell: (info) => {
        const { permission_templates } = info.row.original;
        return permission_templates.length;
      },
    },
    {
      header: 'Actions',
      size: 70,
      cell: (info) => (
        <div className="flex w-full items-center justify-center">
          <IDelete
            className="text-gray-500"
            size={20}
            onClick={() => {
              if (onDelete) onDelete(info.row.original);
            }}
          />
        </div>
      ),
    },
  ];

  return columns;
};

type FormValues = {
  search: string;
};

export const RoleList = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openRoleForm, setOpenRoleForm] = React.useState<boolean>(false);
  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'created_at', desc: false }]);
  const me = useMe();

  const defaultValues: FormValues = { search: '' };
  const defaultValuesWithSearchValues = { ...defaultValues, ...fromQuerystring(searchParams, undefined, undefined, true) };
  const [values, setValues] = React.useState<typeof defaultValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState } = formContext;

  const { data: rowsCount } = useSWR(formState.isValid ? ['/role/count', values, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/role/list', 25, rowsCount, values, sorters, emptyToUndefined), POST);

  const onSubmit = async (values: FormValues) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
    await swr.mutate();
  };

  const handleAddRole = () => {
    setOpenRoleForm(true);
  };

  const handleAddRoleClose = async () => {
    setOpenRoleForm(false);
    await swr.mutate();
  };

  const columns = React.useMemo(
    () =>
      getColumns(async ({ id, name }) => {
        if (!confirm(`Are you sure you want to delete the role ${name}?`)) return;
        await axios.delete(`/role/${id}`);
        swr.mutate();
      }),
    [swr],
  );

  if (!me) return null;

  return (
    <main className="view-container">
      <RoleForm isOpen={openRoleForm} onClose={handleAddRoleClose} onSave={handleAddRoleClose} />
      <section className="flex flex-row place-content-between items-baseline">
        <h4>Roles</h4>
        <div className="flex flex-row gap-4">
          <Button className="blue" LeftIcon={IAdd} onClick={handleAddRole}>
            ROLE
          </Button>
        </div>
      </section>

      <section className="mt-4 rounded-2xl bg-white p-8 pb-4">
        <div>
          <FormProvider {...formContext}>
            <form id="search-form" ref={formRef} className="my-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="flex place-content-between place-items-center">
                <Input
                  className="w-[360px]"
                  name={'search'}
                  placeholder={'Search by ID, Name or Description'}
                  required={true}
                  autoFocus={true}
                  RightIcon={ISearch}
                  autoComplete={'off'}
                  debouncedAutoSubmitForm={'#search-form'}
                  debouncedAutoSubmitTimeout={500}
                />
              </div>
            </form>
          </FormProvider>
        </div>

        <Table
          className={cx('max-h-[calc(100vh-385px)]')}
          columns={columns}
          swr={swr}
          pagination={true}
          rowsCount={rowsCount}
          defaultSorting={sorters}
          onColumnSort={setSorters}
          estimateSize={50}
          overscan={50}
        />
      </section>
    </main>
  );
};
