import * as Sentry from '@sentry/react';
import getBlockchainErrorMessage from 'common/blockchain/getBlockchainError.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import { Button } from 'ui/component/Button.tsx';
import { ICheck, IClose } from 'ui/component/Icons.tsx';
import { Input } from 'ui/control/Input.tsx';
import { useBlockchain } from '#admin/hook/useBlockchain.tsx';

type FormValues = {
  password: string;
  confirmPassword: string;
};

type ValidationItemProps = {
  description: string;
  isEmpty: boolean;
  isValid: boolean;
};

const ValidationItem: React.FC<ValidationItemProps> = ({ description, isEmpty, isValid }) => {
  return (
    <div className="flex w-full flex-row items-center justify-start gap-2">
      {isEmpty && <ICheck className="grey text-lg" />}
      {!isEmpty && isValid && <ICheck className="green text-lg" />}
      {!isEmpty && !isValid && <IClose className="red text-lg" />}
      <p className="body3 grey">{description}</p>
    </div>
  );
};

const validationRules = {
  oneLowercase: /(?=.*[a-z])/,
  oneUppercase: /(?=.*[A-Z])/,
  oneNumber: /(?=.*\d)/,
  oneSpecial: /(?=.*[!@#$%^&*])/,
  eightCharacters: /.{8,}/,
};

export const PasswordReset: React.FC = () => {
  const [isPasswordResetSent, setIsPasswordResetSent] = React.useState(false);
  const [searchParams] = useSearchParams();
  const [error, setError] = React.useState<any>(null);
  const [isExpired, setIsExpired] = React.useState<boolean>(false);
  const { auth, getCurrentBlockTimestamp } = useBlockchain();

  const formMethods = useForm<FormValues>({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: { password: '', confirmPassword: '' },
  });
  const { handleSubmit, formState, watch } = formMethods;
  const { errors } = formState;

  const [newPassword, confirmPassword] = watch(['password', 'confirmPassword']);

  React.useEffect(() => {
    if (!getCurrentBlockTimestamp) return;

    void (async () => {
      const currentTimestamp = await getCurrentBlockTimestamp();
      if (!currentTimestamp) return null;

      const expiresAt = searchParams.get('expiresAt');

      if (expiresAt && currentTimestamp && currentTimestamp > Number(expiresAt)) {
        setIsExpired(true);
      }
    })();
  }, [searchParams, getCurrentBlockTimestamp]);

  const onSubmit = async (values: FormValues) => {
    if (!auth) return;
    const userId = searchParams.get('userId');
    const newPassword = values.password;
    const encodedPasswordResetTokenSignature = searchParams.get('signature');
    if (!(userId && newPassword && encodedPasswordResetTokenSignature)) return;

    const passwordResetTokenSignature = atob(encodedPasswordResetTokenSignature);

    try {
      await auth.setPassword(userId, newPassword, passwordResetTokenSignature);
      setIsPasswordResetSent(true);
    } catch (error: any) {
      console.error(error);
      setError(error);
      Sentry.captureException(error);
    }
  };

  return (
    <main
      className="fixed top-0 h-screen w-screen overflow-auto pt-16"
      style={{ background: 'linear-gradient(111.34deg, #00CBE0 0%, #006BFA 33.33%, #1B1CDD 66.67%, #640BED 100%)' }}
    >
      <FormProvider {...formMethods}>
        <form className="paper m-auto w-[480px] rounded-xl border" autoCorrect={'off'} autoComplete={'off'} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          <div className="flex flex-col items-center gap-4 p-8">
            <div className="mb-6 flex w-full items-center justify-center">
              <img src={'/atlas-path-logo.svg'} width={300} />
            </div>
            <h1 className="text-center font-bold text-2xl">Reset Password</h1>
            <p className="body1 mb-4 inline text-center">
              {isPasswordResetSent ? 'Your password has been reset. You may now login with the new password.' : 'Please enter the new password.'}
            </p>
            <div className="flex w-full flex-col items-center gap-4">
              {!isPasswordResetSent && (
                <>
                  <Input
                    className="w-full"
                    name={'password'}
                    label={'Password'}
                    type={'password'}
                    handleErrorMessages={false}
                    registerOptions={{
                      validate: {
                        oneLowercase: (value) => validationRules.oneLowercase.test(value),
                        oneUppercase: (value) => validationRules.oneUppercase.test(value),
                        oneNumber: (value) => validationRules.oneNumber.test(value),
                        oneSpecial: (value) => validationRules.oneSpecial.test(value),
                        eightCharacters: (value) => validationRules.eightCharacters.test(value),
                      },
                    }}
                  />
                  <Input
                    className="w-full"
                    name={'confirmPassword'}
                    label={'Confirm Password'}
                    type={'password'}
                    handleErrorMessages={false}
                    registerOptions={{ validate: { match: (value) => value === newPassword } }}
                  />
                  <div className="flex w-full flex-col items-start">
                    <p className="body3 mb-2">Your password must contain:</p>
                    <div className="flex w-full flex-col items-start gap-1">
                      <ValidationItem description="One lowercase character" isEmpty={!newPassword} isValid={!errors.password?.types?.oneLowercase} />
                      <ValidationItem description="One uppercase character" isEmpty={!newPassword} isValid={!errors.password?.types?.oneUppercase} />
                      <ValidationItem description="One number" isEmpty={!newPassword} isValid={!errors.password?.types?.oneNumber} />
                      <ValidationItem description="One special character" isEmpty={!newPassword} isValid={!errors.password?.types?.oneSpecial} />
                      <ValidationItem description="8 characters minimum" isEmpty={!newPassword} isValid={!errors.password?.types?.eightCharacters} />
                      <ValidationItem description="Passwords match" isEmpty={!confirmPassword} isValid={confirmPassword === newPassword} />
                    </div>
                  </div>
                  {(isExpired || error) && (
                    <section className="mt-4 grid grid-flow-col justify-center text-center text-red-700">
                      {isExpired ? 'Token is expired' : getBlockchainErrorMessage(error, 'An error happened while trying to reset the password')}
                    </section>
                  )}
                  <Button className="blue mt-4 gap-1" type="submit" disabled={!formState.isValid} loading={formState.isSubmitting}>
                    SUBMIT
                  </Button>
                </>
              )}
              {isPasswordResetSent && (
                <Link to="/auth" className="contents">
                  <Button className="blue mt-4 gap-1">BACK TO LOGIN</Button>
                </Link>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </main>
  );
};
