import type { ColumnDef, SortingState } from '@tanstack/react-table';
import axios from 'axios';
import cx from 'clsx';
import { dateToYMDHM } from 'common/date.ts';
import { POST, emptyToUndefined, fromQuerystring, toQuerystring, truncate } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { ApiKey } from 'types/ApiKey.ts';
import { Button } from 'ui/component/Button.tsx';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { IAdd, IOpenLink, ISearch } from 'ui/component/Icons.tsx';
import { Input } from 'ui/control/Input.tsx';
import { ApiKeyForm } from '#admin/component/ApiKeyForm.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

export const getColumns = (onRevoke: (apiKey: ApiKey) => void) => {
  const columns: ColumnDef<ApiKey>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      cell: (info) => {
        const id = info.getValue<string>();
        return (
          <CopyableText className="font-mono" copyText={id}>
            {truncate(id, 8, '')}
          </CopyableText>
        );
      },
    },
    {
      accessorKey: 'created_at',
      header: 'Creation date',
      cell: (info) => {
        const value = info.getValue<string>();
        const rendered_value = value ? dateToYMDHM(value) : '-';
        return rendered_value;
      },
    },
    {
      accessorKey: 'label',
      header: 'Label',
      cell: (info) => {
        const value = info.getValue<string[]>();
        return <span className="truncate">{value}</span>;
      },
    },
    {
      accessorKey: 'key',
      header: 'Key',
      cell: (info) => {
        const value = info.getValue<string[]>();
        return <span className="truncate">{value}</span>;
      },
    },
    {
      id: 'actions',
      header: '',
      cell: (info) => (
        <Button
          className="red"
          onClick={() => {
            if (onRevoke) onRevoke(info.row.original);
          }}
        >
          REVOKE
        </Button>
      ),
    },
  ];

  return columns;
};

type FormValues = {
  search: string;
};

export const APIKeyList = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openApiKeyForm, setOpenApiKeyForm] = React.useState<boolean>(false);
  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'label', desc: false }]);
  const me = useMe();

  const defaultValues: FormValues = { search: '' };
  const defaultValuesWithSearchValues = { ...defaultValues, ...fromQuerystring(searchParams, undefined, undefined, true) };
  const [values, setValues] = React.useState<typeof defaultValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState } = formContext;

  const { data: rowsCount, mutate: rowsCountMutate } = useSWR(formState.isValid ? ['/api-key/count', values, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/api-key/list', 25, rowsCount, values, sorters, emptyToUndefined), POST);

  const onSubmit = async (values: FormValues) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
    await swr.mutate();
  };

  const handleAddApiKey = () => {
    setOpenApiKeyForm(true);
  };

  const handleAddApiKeyClose = () => {
    setOpenApiKeyForm(false);
    void rowsCountMutate();
    void swr.mutate();
  };

  const columns = React.useMemo(
    () =>
      getColumns(async ({ id, label }) => {
        if (!confirm(`Are you sure you want to revoke API key '${label}'?`)) return;
        await axios.delete(`/api-key/${id}`);
        swr.mutate();
      }),
    [swr],
  );

  if (!me) return null;

  return (
    <main className="view-container">
      <ApiKeyForm isOpen={openApiKeyForm} onClose={handleAddApiKeyClose} onSave={handleAddApiKeyClose} />

      <section className="flex flex-row place-content-between items-baseline">
        <h4>API Keys</h4>

        <div className="flex flex-row gap-4">
          <Link to={new URL('/docs', import.meta.env.VITE_API_URL).href} target="_blank">
            <Button className="blue-outlined" RightIcon={IOpenLink}>
              API Reference
            </Button>
          </Link>
          <Button className="blue" LeftIcon={IAdd} onClick={handleAddApiKey}>
            GENERATE
          </Button>
        </div>
      </section>

      <section className="mt-4 rounded-2xl bg-white p-8 pb-4">
        <div>
          <FormProvider {...formContext}>
            <form id="search-form" ref={formRef} className="my-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <div className="flex place-content-between place-items-center">
                <Input
                  className="w-[360px]"
                  name={'search'}
                  placeholder={'Search by label'}
                  required={true}
                  autoFocus={true}
                  RightIcon={ISearch}
                  autoComplete={'off'}
                  debouncedAutoSubmitForm={'#search-form'}
                  debouncedAutoSubmitTimeout={500}
                />
              </div>
            </form>
          </FormProvider>
        </div>

        <Table
          className={cx('max-h-[calc(100vh-385px)]')}
          columns={columns}
          swr={swr}
          pagination={true}
          rowsCount={rowsCount}
          defaultSorting={sorters}
          onColumnSort={setSorters}
          estimateSize={50}
          overscan={50}
        />
      </section>
    </main>
  );
};
