import cx from 'clsx';
import type React from 'react';
import type { TooltipRefProps } from 'react-tooltip';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { BodyPortal } from '#ui/component/BodyPortal.tsx';

type Props = {
  className?: string;
  text: string;
  children: React.ReactNode;
  tooltipProps?: TooltipRefProps;
  otherProps?: React.HTMLProps<HTMLDivElement>;
};

export const Tooltip: React.FC<Props> = ({ className, text, children, tooltipProps, ...otherProps }) => {
  return (
    <span className={cx(className)} {...otherProps} data-tooltip-id="tooltip" data-tooltip-content={text}>
      <BodyPortal>
        <ReactTooltip id="tooltip" className="!text-[10px] max-w-64" {...tooltipProps} />
      </BodyPortal>
      {children}
    </span>
  );
};
