import { PermissionList as PermissionListComponent } from '#admin/component/PermissionList.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

export const PermissionList = () => {
  const me = useMe();

  if (!me) return null;

  return (
    <main className="view-container">
      <section className="flex flex-row place-content-between items-baseline">
        <h4>Permissions</h4>
      </section>

      <PermissionListComponent className="mt-4 p-8" enableAddPermission={false} showDerived={false} />
    </main>
  );
};
