import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { IDown } from 'ui/component/Icons.tsx';

type Props = {
  title: string;
  children: React.ReactNode;
};

export const HeaderMenu = ({ children, title }: Props) => {
  return (
    <div className="relative flex max-w-sm items-center justify-self-end">
      <Popover className="relative">
        <PopoverButton className="group header-menu-item flex items-center justify-center rounded-md px-2 py-2 outline-none">
          <span className="whitespace-nowrap text-lg">{title}</span>
          <IDown className="ml-2 size-5 text-white transition duration-150 ease-in-out" aria-hidden="true" />
        </PopoverButton>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <PopoverPanel className="-translate-x-1/2 absolute left-1/2 z-50 mt-6 w-48 rounded-2xl shadow-lg">
            <div className="relative flex flex-col gap-4 rounded-2xl bg-white p-4 shadow-lg outline-none">{children}</div>
          </PopoverPanel>
        </Transition>
      </Popover>
    </div>
  );
};
