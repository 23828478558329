import type { ColumnDef, SortingState } from '@tanstack/react-table';
import type { RoleListResponse } from 'api/role/Role.schemas.ts';
import cx from 'clsx';
import { dateToYMDHM } from 'common/date.ts';
import { POST, emptyToUndefined, fromQuerystring, toQuerystring, truncate } from 'common/helpers.ts';
import { isEqual } from 'es-toolkit';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { ROLE } from 'types/Role.ts';
import type { User } from 'types/User.ts';
import { Button } from 'ui/component/Button.tsx';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { IconBadge } from 'ui/component/IconBadge.tsx';
import { IAdd, IFilters, ISearch } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.js';
import { Tab } from 'ui/component/Tab.tsx';
import { Tag } from 'ui/component/Tag.tsx';
import { Input } from 'ui/control/Input.tsx';
import { SelectMulti } from 'ui/control/SelectMulti.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';
import { UserForm } from '#admin/component/UserForm.tsx';
import { UserListItemMenu } from '#admin/component/UserListItemMenu.tsx';

export const getColumns = (onAction?: (action: 'lock' | 'unlock') => void) => {
  const columns: ColumnDef<User>[] = [
    {
      accessorKey: 'id',
      header: 'Atlas ID',
      size: 120,
      cell: (info) => {
        const id = info.getValue<string>();
        return (
          <CopyableText className="font-mono" copyText={id}>
            <Link className="anchor anchor-novisit" to={`/user/${id}`}>
              {truncate(id, 8, '')}
            </Link>
          </CopyableText>
        );
      },
    },
    {
      accessorKey: 'owner_logistics_ex_id',
      header: 'Provider ID',
      size: 120,
      cell: (info) => {
        const logistics_ex_id = info.getValue<string>();
        const owner_logistics_ex_id = info.row.original.owner_logistics_ex_id;
        return logistics_ex_id || owner_logistics_ex_id || '-';
      },
    },
    {
      accessorKey: 'created_at',
      header: 'Creation date',
      cell: (info) => {
        const value = info.getValue<string>();
        const rendered_value = value ? dateToYMDHM(value) : '-';
        return rendered_value;
      },
    },
    {
      accessorKey: 'email',
      header: 'Email',
      minSize: 260,
      cell: (info) => {
        const value = info.getValue<string[]>();
        return (
          <a className="truncate" href={`mailto:${value}`} rel="noreferrer">
            {value}
          </a>
        );
      },
    },
    {
      accessorKey: 'name',
      header: 'Name',
      cell: (info) => {
        const value = info.getValue<string[]>();
        return <span className="truncate">{value}</span>;
      },
    },
    {
      accessorKey: 'group_name',
      header: 'Group',
    },
    {
      accessorKey: 'group_role',
      header: 'Group role',
    },
    {
      accessorKey: 'isActive',
      header: 'Status',
      enableSorting: false,
      size: 100,
      cell: (info) => {
        const value = info.getValue<boolean>();
        return <Tag className={cx('!p-2', value ? 'green' : 'red')}>{value ? 'enabled' : 'disabled'}</Tag>;
      },
    },
    {
      id: 'actions',
      header: '',
      size: 40,
      meta: { className: '!p-0' },
      cell: (info) => <UserListItemMenu onAction={onAction} row={info.row} />,
    },
  ];

  return columns;
};

type FormValues = {
  search: string;
  group_role: ROLE | '';
  is_active: boolean | '';
};

export const UserList = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openUserForm, setOpenUserForm] = React.useState<boolean>(false);
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'created_at', desc: true }]);

  const defaultValues: FormValues = { search: '', group_role: '', is_active: '' };
  const defaultValuesWithSearchValues = { ...defaultValues, ...fromQuerystring(searchParams, undefined, undefined, true) };
  const [values, setValues] = React.useState<typeof defaultValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState, reset, getValues, register, watch } = formContext;

  const { data: roles, isLoading: rolesIsLoading } = useSWR<RoleListResponse>(['/role/list', { name$not: 'MEMBER', sort: [['created_at', 'asc']] }], POST);
  const { data: rowsCount } = useSWR(formState.isValid ? ['/user/count', { ...values, is_group: false }, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/user/list', 25, rowsCount, { ...values, is_group: false }, sorters, emptyToUndefined), POST);

  const toggleFilters = () => setShowFilters(!showFilters);

  const resetFilters = () => {
    reset(defaultValues);
    triggerSubmit();
  };

  // wait next tick or URL will not be updated
  const triggerSubmit = React.useCallback(() => window.setTimeout(() => formRef.current?.requestSubmit(), 50), []);

  const onSubmit = async (values: FormValues) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
    await swr.mutate();
  };

  const onChangeTab = (nextTab: string | number) => {
    const role = String(nextTab) as ROLE | '';
    formContext.setValue('group_role', role);
    triggerSubmit();
  };

  const handleAddUser = () => {
    setOpenUserForm(true);
  };

  const handleAddUserClose = async () => {
    setOpenUserForm(false);
    await swr.mutate();
  };

  // we need to align to chosen tab
  const currentTab = watch('group_role');
  const columns = React.useMemo(() => getColumns(() => void swr.mutate()), [swr]);

  const rolesNames = React.useMemo(() => (roles || []).map((role) => role.name), [roles]);

  const tabs: Record<string, string> = React.useMemo(() => {
    const tabs = { '': 'All' };
    for (const roleName of rolesNames) tabs[roleName] = roleName;
    return tabs;
  }, [rolesNames]);

  // we can't rely only on formState.isDirty because it's true when defaultValues provided to useForm are changed, but we may have filters set by default provided in the URL
  const clearFiltersEnabled = formState.isDirty || !isEqual(defaultValues, getValues());

  // we need active filters: if filter is of type array then we count if having least 1 selection, otherwise we count if being not nullish
  const filtersCount = React.useMemo(() => {
    let result = Object.values(values).filter((value) => (Array.isArray(value) ? value.length > 0 : value !== '')).length;
    if (currentTab !== '') result--;
    return result;
  }, [values, currentTab]);

  return (
    <main className="view-container">
      <UserForm isOpen={openUserForm} onClose={handleAddUserClose} onSave={handleAddUserClose} />
      <section className="flex flex-row place-content-between items-baseline">
        <h4>Users</h4>

        <div>
          <Button className="blue" LeftIcon={IAdd} onClick={handleAddUser}>
            ADD USER
          </Button>
        </div>
      </section>

      <section className="mt-4 rounded-2xl bg-white p-8 pb-4">
        {rolesIsLoading && <Spinner size={24} />}
        {!rolesIsLoading && <Tab tabs={tabs} defaultTab={currentTab} onChange={onChangeTab} />}

        <FormProvider {...formContext}>
          <form id="search-form" ref={formRef} className="my-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <input type="hidden" {...register('group_role')} />

            <div className="flex place-content-between place-items-center">
              <Input
                className="w-[360px]"
                name={'search'}
                placeholder={'Search by ID, Provider ID, Name or Email'}
                required={true}
                autoFocus={true}
                RightIcon={ISearch}
                autoComplete={'off'}
                debouncedAutoSubmitForm={'#search-form'}
                debouncedAutoSubmitTimeout={500}
              />

              <IconBadge Icon={IFilters} className="cursor-pointer" iconClassname={'size-[24px] fill-gray-400'} badge={filtersCount || null} onClick={toggleFilters} />
            </div>

            {/* expanded filters section */}
            <div className={cx('flex flex-col gap-6 rounded-2xl bg-blue-sky p-4', !showFilters && 'hidden')}>
              <div className="flex flex-row gap-3">
                <SelectMulti
                  label={'Status'}
                  name={'is_active'}
                  options={
                    new Map([
                      [true, 'Enabled'],
                      [false, 'Disabled'],
                    ])
                  }
                  selectable={'one'}
                  disableSearch={true}
                  closeOnChangedValue={true}
                  required={false}
                  hasClearAll={true}
                />

                {/* handled by tab changing, we need it otherwise it will be undefined when submitting the form */}
                <SelectMulti className="hidden" label={'Role'} name={'role'} options={rolesNames} selectable={'one'} disableSearch={true} closeOnChangedValue={true} />
              </div>

              <div className="flex flex-row gap-4">
                <Button className="blue-outlined h-max min-w-max max-w-max p-1" type="button" onClick={toggleFilters}>
                  Close
                </Button>
                <Button className="blue-outlined ml-auto min-w-max max-w-max" type="button" onClick={resetFilters} disabled={!clearFiltersEnabled}>
                  Clear filters
                </Button>
                <Button className="blue-outlined min-w-max max-w-max" disabled={showFilters && !formState.isDirty} type="submit">
                  Apply
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>

        <Table
          className={cx('max-h-[calc(100vh-385px)]')}
          columns={columns}
          swr={swr}
          pagination={true}
          rowsCount={rowsCount}
          defaultSorting={sorters}
          onColumnSort={setSorters}
          estimateSize={50}
          overscan={50}
        />
      </section>
    </main>
  );
};
