import cx from 'clsx';
import { getRuntimeTimezone, isGranted, truncate } from 'common/helpers.ts';
import { NavLink } from 'react-router-dom';
import type { Leg as TLeg } from 'services/types/src/Leg.ts';
import type { ROLE } from 'services/types/src/Role.ts';
import { Button } from 'ui/component/Button.tsx';
import { ICamera, IEdit, IInfo, IWarningAmber } from 'ui/component/Icons.tsx';
import { Stepper } from 'ui/component/Stepper.tsx';
import { Tooltip } from 'ui/component/Tooltip.tsx';
import { useMe } from '#admin/hook/useMe.tsx';
import { Datetime } from './Datetime.tsx';
import { DocumentDownload } from './DocumentDownload.tsx';

type Props = {
  leg: TLeg;
  index: number;
  onSupplierAssign: (leg: TLeg) => () => void;
  onDriverAssign: (leg: TLeg) => () => void;
  onOpenUploadDialog: (legs: TLeg[]) => () => void;
  onOpenLegEditDialog: (leg: TLeg) => () => void;
  role: ROLE;
};

export const Leg = ({ leg, index, onSupplierAssign, onDriverAssign, onOpenUploadDialog, onOpenLegEditDialog }: Props) => {
  const me = useMe();
  if (!me) return null;
  return (
    <div key={index} className="paper !bg-blue-sky flex snap-y snap-start flex-col gap-3" data-leg={index + 1} data-leg-status={leg.status}>
      <div className="flex flex-row justify-between">
        <Stepper
          className="w-[100px]"
          steps={[String(index + 1), String(index + 2)]}
          // we will show grey X=>Y stepper if leg is not transiting or delivered
          currentStep={['transiting', 'delivered'].includes(leg.status) ? String(index + 2) : 'xyz'}
          // currentStep={String(currentStep)}
          // currentLegStatus={currentLeg.status}
        />
        {isGranted(me, 'LegEdit', leg.id) && (
          <span onClick={onOpenLegEditDialog(leg)} className="rounded-md p-1 text-blue hover:cursor-pointer hover:bg-gray-100">
            <Tooltip text="Edit Leg">
              <IEdit size={17} />
            </Tooltip>
          </span>
        )}
      </div>
      {isGranted(me, 'LegAssignDriver', leg.id) && (
        <LegDetailItem label="Driver">
          <span className="max-w-full justify-self-start truncate py-3">
            <span className="max-w-full truncate py-3">
              {!leg.driver && !leg.destination_actual_datetime && (
                <Button onClick={onDriverAssign(leg)} className="blue-outlined !min-w-[100px] !max-w-full text-nowrap normal-case">
                  ASSIGN DRIVER
                </Button>
              )}
              {!leg.driver && leg.destination_actual_datetime && <span>-</span>}
              {leg.driver && (
                <div className="blue flex items-center justify-center gap-1">
                  <Tooltip text={leg.driver.name}>
                    <NavLink to={`/user/${leg.driver.id}`} className="rounded-md p-1 hover:bg-gray-100">
                      <span className="uppercase">{`${leg.driver.name} - ${truncate(leg.driver.id, 8, '').toUpperCase()}`}</span>
                    </NavLink>
                  </Tooltip>
                  {!leg.destination_actual_datetime && (
                    <span onClick={onDriverAssign(leg)} className="rounded-md p-1 hover:cursor-pointer hover:bg-gray-100">
                      <Tooltip text="Reassign Driver">
                        <IEdit size={17} />
                      </Tooltip>
                    </span>
                  )}
                </div>
              )}
            </span>
          </span>
        </LegDetailItem>
      )}
      <hr />
      {isGranted(me, 'LegAssignSupplier', leg.id) && (
        <>
          <LegDetailItem label="Supplier">
            <span className="max-w-full justify-self-start truncate py-3">
              <span className="max-w-full truncate py-3">
                {!leg.agent && !leg.destination_actual_datetime && (
                  <Button onClick={onSupplierAssign(leg)} className="blue-outlined !min-w-[100px] !max-w-full text-nowrap normal-case">
                    ASSIGN SUPPLIER
                  </Button>
                )}
                {!leg.agent && leg.destination_actual_datetime && <span>-</span>}
                {leg.agent && (
                  <div className="blue flex items-center justify-center gap-1">
                    <Tooltip text={leg.agent.name}>
                      <NavLink to={`/user/${leg.agent.id}`} className="rounded-md p-1 hover:bg-gray-100">
                        <span className="uppercase">{`${leg.agent.name} - ${truncate(leg.agent.id, 8, '').toUpperCase()}`}</span>
                      </NavLink>
                    </Tooltip>
                    {!leg.destination_actual_datetime && (
                      <span onClick={onSupplierAssign(leg)} className="rounded-md p-1 hover:cursor-pointer hover:bg-gray-100">
                        <Tooltip text="Reassign Supplier">
                          <IEdit size={17} />
                        </Tooltip>
                      </span>
                    )}
                  </div>
                )}
              </span>
            </span>
          </LegDetailItem>
          <LegDetailItem label="RTE">{leg.supplier_reference_id ?? 'N/A'}</LegDetailItem>
          <hr />
        </>
      )}
      <LegDetailItem label="Pickup address">{`${leg.origin_address_name ? leg.origin_address_name + ' / ' : ''}${leg.origin_address}`}</LegDetailItem>
      <LegDetailItem label="Expected pickup time">
        <Datetime truncateRegion={true} rangeFrom={leg.origin_expected_from_datetime} timezone={leg.origin_tz || getRuntimeTimezone()}>
          {leg.origin_expected_datetime}
        </Datetime>
      </LegDetailItem>
      <LegDetailItem label="Actual pickup time">
        <div className="flex items-center justify-center gap-2">
          <Datetime timezone={leg.origin_tz || getRuntimeTimezone()} truncateRegion={true}>
            {leg.origin_actual_datetime}
          </Datetime>
          {leg.origin_manual_actual_datetime && (
            <Tooltip text="This leg was collected manually">
              <IWarningAmber size={18} />
            </Tooltip>
          )}
        </div>
      </LegDetailItem>
      <hr />
      <LegDetailItem label="Delivery address">{`${leg.destination_address_name ? leg.destination_address_name + ' / ' : ''}${leg.destination_address}`}</LegDetailItem>
      <LegDetailItem label="Expected delivery time">
        <Datetime truncateRegion={true} rangeFrom={leg.destination_expected_from_datetime} timezone={leg.destination_tz || getRuntimeTimezone()}>
          {leg.destination_expected_datetime}
        </Datetime>
      </LegDetailItem>
      <LegDetailItem label="Actual delivery time">
        <div className="flex items-center justify-center gap-2">
          <Datetime timezone={leg.destination_tz || getRuntimeTimezone()} truncateRegion={true}>
            {leg.destination_actual_datetime}
          </Datetime>
          {leg.destination_manual_actual_datetime && (
            <Tooltip text="This leg was delivered manually">
              <IWarningAmber size={18} />
            </Tooltip>
          )}
        </div>
      </LegDetailItem>
      <hr />

      <h3 className="body2 mt-2">Documents</h3>
      <div className="flex flex-row flex-wrap gap-3">
        {leg.documents.map((document, index) => (
          <DocumentDownload documentUrl={document.url} key={index} />
        ))}
      </div>

      <Button LeftIcon={ICamera} className="blue-outlined relative w-max gap-2" onClick={onOpenUploadDialog([leg])}>
        <div className="flex flex-row items-center gap-2">
          <span>UPLOAD DOC.</span>
          <Tooltip text="Upload document specifically to this leg.">
            <IInfo className="grey" size={16} />
          </Tooltip>
        </div>
      </Button>
    </div>
  );
};

type LegDetailItemProps = {
  label: string;
  hasError?: boolean;
  children: React.ReactNode;
};

export const LegDetailItem: React.FC<LegDetailItemProps> = ({ label, hasError, children }) => (
  <span className="flex flex-row place-content-between items-center gap-2">
    <span className="text-grey-label">{label}</span>
    <strong className={cx('text-right', hasError ? 'red' : null)}>{children}</strong>
  </span>
);
