import cx from 'clsx';
import { GET, truncate } from 'common/helpers.ts';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR from 'swr';
import type { User } from 'types/User.ts';
import { Breadcrumb } from 'ui/component/Breadcrumb.tsx';
import { Button } from 'ui/component/Button.tsx';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { IEdit, ILocked, IRefresh, IUnlocked } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Tab } from 'ui/component/Tab.tsx';
import { Tag } from 'ui/component/Tag.tsx';
import { GroupForm } from '#admin/component/GroupForm.tsx';
import { PermissionList } from '#admin/component/PermissionList.tsx';
import { type DialogConfig, UserDialog } from '#admin/component/UserDialog.tsx';
import { UserForm } from '#admin/component/UserForm.tsx';
import { UserList } from '#admin/component/UserList.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

const defaultTab = 'Permissions';

export const UserDetail: React.FC = () => {
  const { id: userId } = useParams();
  const me = useMe();
  const tabs: Record<string, string> = { [defaultTab]: defaultTab };
  const userSWR = useSWR<User>([`/user/${userId}`], GET);
  const [dialogConfig, setDialogConfig] = React.useState<DialogConfig | null>(null);
  const [openEditDialog, setOpenEditDialog] = React.useState<boolean>(false);
  const [currentTab, setCurrentTab] = React.useState(defaultTab);

  const { data: user } = userSWR;

  if (!user || !me) return <Spinner centered={true} />;
  if (user.is_group) tabs.Users = 'Users';

  const isMe = userId === me.id;
  const isEnabled = user.isActive;

  const handleEdit = () => setOpenEditDialog(true);

  const handleResetCredentials = () => setDialogConfig({ action: 'reset-credentials' });

  const handleLock = () => setDialogConfig({ action: 'lock' });

  const handleUnlock = () => setDialogConfig({ action: 'unlock' });

  const onDialogClosed = () => {
    setDialogConfig(null);
    setOpenEditDialog(false);
    void userSWR.mutate();
  };

  return (
    <main className="view-container flex flex-col gap-4">
      {openEditDialog &&
        (user.is_group ? (
          <GroupForm isOpen={true} onClose={onDialogClosed} onSave={onDialogClosed} groupId={userId} />
        ) : (
          <UserForm isOpen={true} onClose={onDialogClosed} onSave={onDialogClosed} userId={userId} />
        ))}
      {dialogConfig && <UserDialog user={user} config={dialogConfig} onSave={onDialogClosed} onClose={onDialogClosed} isOpen={true} />}
      {!user.is_group && <Breadcrumb className="mb-6" items={[{ label: 'Users', path: '/users' }, { label: 'User details' }]} />}
      {user.is_group && <Breadcrumb className="mb-6" items={[{ label: 'Groups', path: '/groups' }, { label: 'Group details' }]} />}

      <section className="paper !p-6 flex flex-col gap-6">
        <Detail label={'Name'}>
          <strong>{user.name || '-'}</strong>
        </Detail>
        <Detail label={'Atlas ID'}>
          <CopyableText className="font-mono" copyText={userId || '-'}>
            {truncate(userId || '-', 8, '')}
          </CopyableText>
        </Detail>
        <Detail label={'Provider ID'}>{user.logistics_ex_id || user.owner_logistics_ex_id || '-'}</Detail>
        <Detail label={'Parent Group'}>
          {user.group_name ? (
            <Link to={`/user/${user.owner_id}`}>
              <span className="anchor anchor-novisit">{user.group_name}</span>
            </Link>
          ) : (
            <span className="text-grey-label">-</span>
          )}
        </Detail>
        <Detail label={'Status'}>
          <Tag className={cx('!p-2', user.isActive ? 'green' : 'red')}>{user.isActive ? 'enabled' : 'disabled'}</Tag>
        </Detail>
        <Detail label={'Email'}>{user.email || '-'}</Detail>
        <Detail label={'Company name'}>{user.company_name || '-'}</Detail>
        <Detail label={'Phone Number'}>{user.phone || '-'}</Detail>
        <Detail label={'Role'}>
          <Tag className="blue !p-2">{user.role}</Tag>
        </Detail>
        {user.is_group && <Detail label={'VAT'}>{user.vat_number || '-'}</Detail>}
        {user.is_group && <Detail label={'Headquarters'}>{user.address || '-'}</Detail>}

        <hr />

        {!user.deleted_at && (
          <div className="flex w-full gap-2">
            <Button className="blue max-w-max" onClick={handleEdit} LeftIcon={IEdit}>
              EDIT
            </Button>
            {!user.sso_only && (
              <Button className="blue-outlined max-w-max" onClick={handleResetCredentials} LeftIcon={IRefresh}>
                RESET CREDENTIALS
              </Button>
            )}
            {!isMe && (
              <Button className="blue-outlined max-w-max" onClick={isEnabled ? handleLock : handleUnlock} LeftIcon={isEnabled ? ILocked : IUnlocked}>
                {(isEnabled && 'LOCK') || 'UNLOCK'}
              </Button>
            )}
          </div>
        )}
        {user.deleted_at && <p className="red">This user has been deleted.</p>}
      </section>

      <section className="paper !p-6 !pt-2 flex flex-col gap-4">
        <Tab tabs={tabs} defaultTab={defaultTab} onChange={(tab) => setCurrentTab(String(tab))} />
        {currentTab === 'Permissions' && <PermissionList className="!px-0" user={user} enableAddPermission={true} />}
        {currentTab === 'Users' && <UserList className="!px-0" user={user} />}
      </section>
    </main>
  );
};

type Props = {
  label: string;
  children: React.ReactNode;
};

export const Detail: React.FC<Props> = ({ label, children }) => (
  <span className="body1 flex flex-row items-start gap-16">
    <span className="w-48 text-grey-label">{label}</span>
    <span className="">{children}</span>
  </span>
);
