import type { ColumnDef, SortingState } from '@tanstack/react-table';
import axios from 'axios';
import cx from 'clsx';
import { POST, emptyToUndefined, fromQuerystring, toQuerystring, truncate, valueToYESNO } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Link, useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { Permission } from 'types/Permission.ts';
import type { User } from 'types/User.ts';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { IDelete, ISearch } from 'ui/component/Icons.tsx';
import { Input } from 'ui/control/Input.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';
import { useMe } from '#admin/hook/useMe.tsx';
import { AddPermissionForm } from './AddPermissionForm.tsx';

export const getColumns = (showDerivedColumn: boolean, onDelete: (permission: Permission) => void) => {
  const defaultColumns: ColumnDef<Permission>[] = [
    {
      accessorKey: 'id',
      header: 'ID',
      size: 120,
      cell: (info) => {
        const id = info.getValue<string>();
        return (
          <CopyableText className="font-mono" copyText={id}>
            {truncate(id, 8, '')}
          </CopyableText>
        );
      },
    },
    {
      accessorKey: 'subject_name',
      header: 'Subject',
      size: 220,
      cell: (info) => {
        const value = info.getValue<string>();
        const row = info.row.original;
        const subject_id = row.subject;
        if (subject_id === '*') return <span>*</span>;
        else if (value) {
          return (
            <Link className="anchor anchor-novisit" to={`/user/${subject_id}`}>
              {value} {row.subject_is_group ? '/*' : ''}
            </Link>
          );
        } else {
          return (
            <CopyableText className="font-mono" copyText={row.subject}>
              {truncate(row.subject, 8, '')}
            </CopyableText>
          );
        }
      },
    },
    {
      accessorKey: 'operation',
      header: 'Operation',
    },
    {
      accessorKey: 'resource_name',
      header: 'Resource',
      size: 220,
      cell: (info) => {
        const value = info.getValue<string>();
        const row = info.row.original;
        const resource_id = row.resource;
        if (resource_id === '*') return <span>*</span>;
        else if (value) {
          return (
            <Link className="anchor anchor-novisit" to={`/user/${resource_id}`}>
              {value} {row.resource_is_owner ? '/*' : ''}
            </Link>
          );
        } else {
          return (
            <CopyableText className="font-mono" copyText={row.resource}>
              {truncate(row.resource, 8, '')}
            </CopyableText>
          );
        }
      },
    },
    {
      id: 'derived',
      accessorKey: 'derived',
      header: 'Derived from group',
      cell: (info) => {
        const value = info.getValue<string>();
        const result = valueToYESNO(value);
        return result;
      },
    },
    {
      header: 'Actions',
      size: 70,
      cell: (info) => (
        <div className="flex w-full items-center justify-center">
          {!info.row.original.derived && (
            <IDelete
              className="text-gray-500"
              size={20}
              onClick={() => {
                if (onDelete) onDelete(info.row.original);
              }}
            />
          )}
        </div>
      ),
    },
  ];

  let columns = [...defaultColumns];
  // if user is supplier we need to show other columns
  if (showDerivedColumn === false) {
    columns = columns.filter((column) => column.id !== 'derived');
  }
  return columns;
};

type Props = {
  className?: string;
  user?: User;
  title?: string;
  enableAddPermission: boolean;
  showDerived?: boolean;
};

type FormValues = {
  search: string;
};

export const PermissionList = ({ className, title, user, enableAddPermission, showDerived }: Props) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [sorters, setSorters] = React.useState<SortingState>(
    (showDerived ? [{ id: 'derived', desc: false }] : []).concat(
      ...[
        { id: 'subject', desc: false },
        { id: 'operation', desc: false },
        { id: 'resource', desc: false },
      ],
    ),
  );
  const me = useMe();

  const defaultValues: FormValues = { search: '' };
  const defaultValuesWithSearchValues = { ...defaultValues, ...fromQuerystring(searchParams, undefined, undefined, true) };
  const [values, setValues] = React.useState<typeof defaultValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState } = formContext;

  const { data: rowsCount } = useSWR(formState.isValid ? ['/permission/count', { ...values, subject: user?.id, derived: showDerived }, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/permission/list', 25, rowsCount, { ...values, subject: user?.id, derived: showDerived }, sorters, emptyToUndefined), POST);

  const onSubmit = async (values: FormValues) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
    await swr.mutate();
  };

  const columns = React.useMemo(
    () =>
      getColumns(showDerived === undefined, async ({ id }) => {
        if (!confirm('Are you sure you want to delete the permission?')) return;
        await axios.delete(`/permission/${id}`);
        swr.mutate();
      }),
    [swr, showDerived],
  );

  if (!me) return null;

  return (
    <div className={cx(className, 'flex flex-col gap-4 rounded-2xl bg-white')}>
      {title && <h4>{title}</h4>}
      {enableAddPermission && (
        <section className="py-0">
          <AddPermissionForm user={user} onAdd={() => void swr.mutate()} />
        </section>
      )}
      <section>
        <FormProvider {...formContext}>
          <form id="search-form" ref={formRef} className="mb-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex place-content-between place-items-center">
              <Input
                className="w-[400px]"
                name={'search'}
                placeholder={'Search by ID, Subject, Resource, Operation'}
                required={true}
                autoFocus={true}
                RightIcon={ISearch}
                autoComplete={'off'}
                debouncedAutoSubmitForm={'#search-form'}
                debouncedAutoSubmitTimeout={500}
              />
            </div>
          </form>
        </FormProvider>
        <Table
          className={cx('max-h-[calc(100vh-385px)]')}
          columns={columns}
          swr={swr}
          pagination={true}
          rowsCount={rowsCount}
          defaultSorting={sorters}
          onColumnSort={setSorters}
          estimateSize={50}
          overscan={50}
        />
      </section>
    </div>
  );
};
