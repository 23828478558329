import { MPOST } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { Button } from 'ui/component/Button.tsx';
import { ICheck } from 'ui/component/Icons.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Input } from 'ui/control/Input.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

type FormValues = {
  label: string;
};

export type ApiKeyFormProps = {
  onSave?: () => void;
  onClose?: () => void;
  isOpen: boolean;
};

export const ApiKeyForm = ({ onSave, onClose, isOpen }: ApiKeyFormProps) => {
  const me = useMe();
  const createMutation = useSWRMutation('/api-key', MPOST);
  const [generatedKey, setGeneratedKey] = React.useState<string | null>(null);

  const isLoading = !!createMutation.isMutating;
  const isError = createMutation.error;

  const formMethods = useForm<FormValues>({
    mode: 'onTouched',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      label: '',
    },
  });

  const { handleSubmit, formState } = formMethods;

  const onSubmit = async ({ label }: FormValues) => {
    if (generatedKey) {
      await navigator?.clipboard?.writeText(generatedKey);
      setGeneratedKey(null);
      if (onSave) onSave();
      return;
    }
    const result = await createMutation.trigger({ label });
    setGeneratedKey(result?.key);
  };

  const handleClose = () => {
    setGeneratedKey(null);
    if (onClose) onClose();
  };

  return (
    <Modal title={<h3>Generate API Key</h3>} isOpen={isOpen} onClose={handleClose}>
      {(!me || isLoading) && <Spinner centered={true} overlay={true} blur={true} />}

      <FormProvider {...formMethods}>
        <form className="flex w-[600px] flex-col gap-6" autoCorrect={'off'} autoComplete={'off'} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          <Input
            className="w-full"
            name={'label'}
            label={'Label'}
            placeholder="e.g. 'API Key for my app'"
            registerOptions={{ required: true }}
            disabled={generatedKey !== null}
            autoFocus={false}
          >
            {formState.errors.label?.type === 'required' ? 'Required' : null}
          </Input>

          {generatedKey && (
            <div className="flex flex-col gap-2">
              <p>Please copy the key and store it securely.</p>
              <p>
                <b>You will not be able to see it again. If you lose it, you will have to generate a new key.</b>
              </p>
              <div className="flow-text break-words border border-dashed bg-slate-50 p-2 font-mono">{generatedKey}</div>
            </div>
          )}

          <div className="mt-8 flex items-center gap-4">
            <Button type="button" className="blue-outlined col-span-2 justify-self-end" onClick={onClose}>
              BACK
            </Button>
            <Button
              type={'submit'}
              className="blue col-span-2 justify-self-end"
              loading={isLoading}
              disabled={!formState.isValid || !formState.isDirty}
              RightIcon={!isLoading && formState.isSubmitSuccessful ? ICheck : undefined}
            >
              {generatedKey ? 'COPY' : 'GENERATE'}
            </Button>
          </div>

          {!!isError && <pre className="red">There was an error while processing your request. Please try again later.</pre>}
        </form>
      </FormProvider>
    </Modal>
  );
};
