import { isGranted } from 'common/helpers.ts';
import { useDispatch } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { ILogout, IUser } from 'ui/component/Icons.tsx';
import { useMe } from '#admin/hook/useMe.tsx';
import { actions } from '#admin/reducer/app.ts';
import { HeaderMenu } from './HeaderMenu.tsx';

export const Header = () => {
  const me = useMe();
  if (!me) return null;

  return (
    <header className="fixed top-0 left-0 z-10 flex h-[65px] w-screen justify-center border-b bg-blue py-3">
      <nav className="container flex">
        <Link className="contents" to={'/'}>
          <img className="mx-5 max-h-[42px]" height="42px" width="121px" src="/atlas-path-nav-logo.svg" alt="Atlas Path Logo" />
        </Link>
        <section className="flex w-full items-center justify-center gap-8 text-lg">
          <NavLink className="header-menu-item" to={'/home'}>
            Overview
          </NavLink>
          <NavLink className="header-menu-item" to={'/shipments'}>
            Shipments
          </NavLink>
          {isGranted(me, 'ViewTrackers') && (
            <NavLink className="header-menu-item" to={'/trackers'}>
              Trackers
            </NavLink>
          )}
          {isGranted(me, 'ViewAlerts') && (
            <NavLink className="header-menu-item" to={'/alerts'}>
              Alerts
            </NavLink>
          )}
          {isGranted(me, 'ViewTec4CloudConfig') && (
            <NavLink className="header-menu-item" to={'/tec4cloud-config'}>
              Tec4Cloud
            </NavLink>
          )}
          {isGranted(me, 'ViewApiKeys') && (
            <NavLink className="header-menu-item" to={'/api-keys'}>
              API Keys
            </NavLink>
          )}
          {isGranted(me, 'ViewActivities') && (
            <NavLink className="header-menu-item" to={'/activities'}>
              Activity
            </NavLink>
          )}
          <UserMenu me={me} />
        </section>
        <LoginMenu me={me} />
      </nav>
    </header>
  );
};

type UserMenuProps = { me: any };

const UserMenu = ({ me: _me }: UserMenuProps) => (
  <HeaderMenu title="Users">
    <>
      <Link className="flex flex-row items-center gap-4 hover:opacity-85" to={'/users'}>
        <span>Users</span>
      </Link>
      <Link className="flex flex-row items-center gap-4 hover:opacity-85" to={'/groups'}>
        <span>Groups</span>
      </Link>
      <Link className="flex flex-row items-center gap-4 hover:opacity-85" to={'/permissions'}>
        <span>Permissions</span>
      </Link>
      <Link className="flex flex-row items-center gap-4 hover:opacity-85" to={'/roles'}>
        <span>Roles</span>
      </Link>
    </>
  </HeaderMenu>
);

type LoginMenuProps = { me: any };
const LoginMenu = ({ me }: LoginMenuProps) => {
  const dispatch = useDispatch();
  const logout = () => dispatch(actions.logout());

  return (
    <HeaderMenu title={me.name}>
      <>
        <Link className="flex flex-row items-center gap-4 hover:opacity-85" to={`/user/${me.id}`}>
          <IUser size={24} />
          <span>Profile</span>
        </Link>
        <Link className="flex flex-row items-center gap-4 hover:opacity-85" to={'#'} onClick={logout}>
          <ILogout size={24} />
          <span>Logout</span>
        </Link>
      </>
    </HeaderMenu>
  );
};
