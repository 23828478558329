import type { UserGetSubtreeResponse } from 'api/user/User.schemas.ts';
import { GET } from 'common/helpers.ts';
import React, { type ReactNode } from 'react';
import Tree from 'react-d3-tree';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { BodyPortal } from 'ui/component/BodyPortal.js';
import { Button } from 'ui/component/Button.tsx';
import { IGroup, ITable, IUser } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

/** Refs:
 * https://stackoverflow.com/questions/62553288/how-to-custom-orientation-in-react-d3-tree
 * https://codesandbox.io/p/sandbox/rd3t-v2-custom-with-foreignobject-0mfj8?file=%2Fsrc%2FApp.js%3A45%2C1-47%2C1
 */

type TreeNode = {
  id: string;
  owner_id: string | null;
  is_group: boolean;
  name: string;
  attributes: { ID: string; EXID: string; role: string };
  users_list: string[];
  children: TreeNode[];
};

export const GroupDiagram = () => {
  const [translate, containerRef] = useCenteredTree();
  const me = useMe();
  const navigate = useNavigate();
  const { data, isLoading } = useSWR<UserGetSubtreeResponse>(me ? [`/user/${me.owner_id || me.id}/subtree`] : null, GET);

  const hierarchy = React.useMemo(() => {
    if (!me || !data) return [];

    const users: TreeNode[] = data.map((user) => ({
      id: user.id,
      owner_id: user.owner_id,
      is_group: user.is_group,
      name: user.name,
      children: [],
      attributes: { ID: user.id.slice(0, 8), EXID: user.logistics_ex_id || '-', role: user.role },
      users_list: [],
    }));

    const root = users.find((user) => user.id === me.owner_id);
    if (!root) throw new Error('Root node not found');

    const tree = buildUserTree(root, users);
    return tree;
  }, [me, data]);

  if (!me || isLoading) <Spinner centered={true} />;
  if (!data || !data.length) return null;

  const nodeSize = { x: 170, y: 140 };
  const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: nodeSize.x / -2, y: nodeSize.y / -2 };

  return (
    <main className="view-container">
      <section className="flex flex-row place-content-between items-baseline">
        <h4>Groups</h4>
        <div className="flex flex-row gap-2">
          <Button className="blue-outlined" LeftIcon={ITable} onClick={() => navigate('/groups')}>
            Table view
          </Button>
        </div>
      </section>

      <section ref={containerRef} className="paper mt-4 h-[calc(100vh-200px)] rounded-2xl p-8 pb-4">
        <Tree
          data={hierarchy}
          orientation="vertical"
          pathFunc="step"
          translate={translate}
          separation={{ siblings: 2, nonSiblings: 2 }}
          renderCustomNodeElement={(rd3tProps) => <UserNodeLabel foreignObjectProps={foreignObjectProps} {...rd3tProps} />}
        />
      </section>
    </main>
  );
};

const UserNodeLabel = ({ nodeDatum, foreignObjectProps }) => {
  const [expanded, setExpanded] = React.useState(false);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });

  const onClick = React.useCallback(
    (event) => {
      setExpanded(!expanded);
      if (expanded) return;
      const rect = event.target.closest('foreignObject').getBoundingClientRect();
      setPosition({ top: rect.top, left: rect.right + window.scrollX + 10 }); // position next to the element on the right (with a small margin)
    },
    [expanded],
  );

  return (
    <React.Fragment>
      <foreignObject {...foreignObjectProps} onClick={nodeDatum.is_group ? onClick : undefined}>
        <div className="flex-col gap-2 border border-black bg-gray-200 p-2">
          <div title={nodeDatum.name} className="flex flex-row items-center gap-2">
            {nodeDatum.is_group ? <IGroup size="24" /> : <IUser size="20" />}
            <strong className="flex-1 truncate">{nodeDatum.name}</strong>
          </div>
          {Object.entries(nodeDatum.attributes).map(([key, value]) => (
            <p key={key}>
              {key}: {value as ReactNode}
            </p>
          ))}
          <p className="font-bold">{nodeDatum.users_list.length} users</p>
        </div>
        {expanded && (
          <BodyPortal>
            <div className="fixed z-50 border border-black bg-white p-2" style={{ top: `${position.top}px`, left: `${position.left}px` }}>
              {!nodeDatum.users_list.length && <p>No users</p>}
              {nodeDatum.users_list.map((name) => (
                <p key={name}>{name}</p>
              ))}
            </div>
          </BodyPortal>
        )}
      </foreignObject>
    </React.Fragment>
  );
};

const useCenteredTree = (): [{ x: number; y: number }, React.RefCallback<HTMLDivElement>] => {
  const [translate, setTranslate] = React.useState({ x: 0, y: 0 });

  const containerRef = React.useCallback((containerElem: HTMLDivElement | null) => {
    if (!containerElem) return;
    const { width, height } = containerElem.getBoundingClientRect();
    setTranslate({ x: width / 2, y: height / 10 });
  }, []);

  return [translate, containerRef];
};

const buildUserTree = (user: TreeNode, users: TreeNode[]): TreeNode[] => {
  const children = users.filter((u) => u.owner_id === user.id);
  children.forEach((user) => buildUserTree(user, users));
  children.sort((a, b) => b.children.length - a.children.length);

  // we can't render every user or tree would be impossible to see, so we only render the groups and have a recap of users in the group and give the number of users in the group
  for (const group of [...children.filter((user) => user.is_group), user]) {
    // we need to compute the user list for the current user also
    const group_users = users.filter((user) => user.owner_id === group.id && !user.is_group);
    const users_list = group_users.map((user) => user.name).sort();
    group.users_list = users_list;
  }

  user.children = children.filter((u) => u.is_group);
  return [user];
};
