const ErrorMessages = {
  AUTH_INVALID_CREDENTIALS: 'Invalid credentials.',
  AUTH_NO_PERMISSIONS: 'Your account is not allowed to access the application.',
  AUTH_USER_NOT_EXISTS_IN_THE_AUTH: 'User does not exist in the Auth.',
  AUTH_USER_IS_LOCKED: 'Your account is locked. Please contact your administrator for assistance.',
  AUTH_USER_NOT_FOUND: 'User not found for this token.',
  AUTH_SMART_CONTRACT_NOT_FOUND: (authAddress: string) => `Auth contract not found at address "${authAddress}"`,
  AUTH_SMART_CONTRACT_USERS_NOT_CREATED: 'Users not created.',
  AUTH_SMART_CONTRACT_USERS_NOT_UPDATED: 'Users not updated.',
  AUTH_SMART_CONTRACT_PASSWORD_NOT_RECOVERED: 'Password not recovered.',
  ALERT_PACKAGE_ID_OR_LEG_ID_IS_REQUIRED: 'Package ID or Leg ID is required',
  API_KEY_NOT_FOUND: 'API Key not found.',
  API_KEY_INVALID_USER: 'API Key does not belong to the user.',
  ASYNC_STORAGE_USER_NOT_FOUND: 'User not found in async storage.',
  CONSIGNEE_NOT_FOUND: 'Consignee not found',
  CURRENT_BLOCK_NOT_FOUND: 'Current block not found',
  SMART_CONTRACT_NOT_FOUND: (address: string) => `Smart contract not found at address "${address}"`,
  OWNER_NOT_FOUND: 'Owner not found',
  CUSTOMER_NOT_FOUND: 'Customer not found',
  DOCUMENT_DOWNLOAD_FAILED: 'Failed to download the document',
  DOCUMENT_NOT_FOUND: 'Document not found',
  DOCUMENT_URL_IS_REQUIRED: 'Document URL is required.',
  DRIVER_NOT_ASSIGNED: 'Driver not assigned',
  DRIVER_NOT_FOUND: 'Driver not found',
  EMAIL_SEND_FAILED: 'Failed to send email',
  FILE_ERROR_READING: 'Error reading file',
  FILE_FAILED_TO_READ_TO_BUFFER: 'Failed to read file to buffer.',
  FILE_INVALID: 'Invalid file.',
  FILENAME_NOT_FOUND: (url: string) => `Filename not found in document URL "${url}"`,
  HEALTH_BLOCK_TIME_EXCEEDED: (blockNumber: number, maxTimeAllowed: number) => `Block ${blockNumber} took more than ${maxTimeAllowed}s to be mined.`,
  HEALTH_BLOCKS_NOT_FOUND: 'Blocks not found.',
  INTERNAL_SERVER_ERROR: 'Internal server error.',
  INVALID_FILENAME: 'Invalid filename.',
  INVALID_USER_ID: 'Invalid user ID.',
  INVALID_PERMISSION_TEMPLATE: 'Invalid permission template.',
  INVALID_QUERY_STRING: 'Invalid query string',
  INVALID_VALUE_FOR_TYPE: (type: string, value: string) => `Invalid value "${value}" for type "${type}"`,
  LEG_AGENT_ID_IS_REQUIRED: 'Agent ID is required',
  LEG_DOCUMENT_ALREADY_EXISTS: 'Document already exists for this leg',
  LEG_IS_ALREADY_COLLECTED: 'Leg is already collected',
  LEG_IS_ALREADY_DELIVERED: 'Leg is already delivered',
  LEG_MUST_BE_COLLECTED_BEFORE_DELIVERING: 'Leg must be collected first before delivering',
  LEG_NOT_FOUND: 'Leg not found',
  LEG_AGENT_NOT_FOUND: 'Agent not found',
  LEG_DRIVER_NOT_FOUND: 'Driver not found',
  LEG_POSITION_SHOULD_NOT_BE_NULL: 'All legs must have a non-null origin_position and destination_position.',
  LEG_WITH_ALERT_TYPE_NOT_FOUND: (alertType: string) => `Leg with alert type ${alertType} not found.`,
  LOGISTICS_CONNECTOR_PULL_NOT_INVOKED: 'LogisticsConnector.pull() was not invoked after LogisticsConnector.downloadIntegrationFile() check.',
  LOGISTICS_CONNECTOR_PUSH_NOT_INVOKED: 'LogisticsConnector.push() was not invoked after LogisticsConnector.downloadIntegrationFile() check.',
  LOGISTICS_CONNECTOR_SHIPMENT_NOT_FOUND: (shipmentId: string) => `Shipment #${shipmentId} just imported could not be found.`,
  METRICS_CONNECTOR_SHIPMENT_NOT_FOUND: (shipmentId: string) => `Shipment #${shipmentId} not found on Metrics Connector.`,
  METRICS_CONNECTOR_GEOFENCE_NOT_FOUND: (packageId: string) => `Geofence not found for package: ${packageId}`,
  METRICS_CONNECTOR_PACKAGE_NOT_FOUND: (packageId: string) => `Package not found: ${packageId}`,
  METRICS_CONNECTOR_PACKAGE_NOT_FOUND_IN_SHIPMENT: (packageId: string) => `Package not found in shipment: ${packageId}`,
  METRICS_CONNECTOR_PACKAGE_UNKNOWN_THRESHOLDS: (packageId: string, minTemperature: number | null, maxTemperature: number | null) =>
    `Package ${packageId} is using unknown thresholds: ${minTemperature}ºC/${maxTemperature}ºC. Please set it on Tec4Cloud project or remove it from the package.`,
  METRICS_CONNECTOR_SHIPMENT_NOT_FOUND_FOR_PACKAGE: (packageId: string) => `Shipment not found for package: ${packageId}`,
  METRICS_CONNECTOR_TRACKER_NOT_FOUND: (trackerId: string) => `Tracker not found: ${trackerId}`,
  ONLY_ADMINS_CAN_ASSIGN_TRACKERS: 'Only admins can assign trackers',
  ONLY_ADMINS_CAN_UNASSIGN_TRACKERS: 'Only admins can unassign trackers',
  ONLY_AGENTS_CAN_ASSIGN_DRIVERS: 'Only agents can assign drivers',
  ONLY_AGENTS_CAN_UNASSIGN_DRIVERS: 'Only agents can unassign drivers',
  OWNER_ID_IS_REQUIRED: 'Owner ID is required',
  ORGANIZATION_NOT_FOUND: 'Organization not found',
  PACKAGE_NOT_FOUND: 'Package not found',
  PACKAGE_TEMPLATE_NOT_FOUND: 'Package template not found',
  ALERT_TEMPLATE_NOT_FOUND: 'Alert template not found',
  GEOFENCE_NOT_FOUND: 'Geofence data not found',
  PACKAGE_WITH_ALERT_TYPE_NOT_FOUND: (alertType: string) => `Package with alert type ${alertType} not found.`,
  PADDING_EXCEEDS_DATA_LENGTH: 'Padding exceeds data length',
  PDF_BLOB_IS_NOT_AVAILABLE: 'PDF blob is not available',
  POSITION_MUST_BE_AN_ARRAY: 'Position must be an array',
  RESOURCE_NOT_FOUND: 'Resource not found.',
  REPOSITORY_RESOURCE_NOT_FOUND: (id: string) => `Resource with id=${id} cannot be updated because it does not satisfy the where clause.`,
  REPOSITORY_OWNER_ID_IS_REQUIRED: (tableName: string) => `[${tableName}] Owner ID is required for write operations.`,
  REQUEST_TRANSACTION_TIMEOUT: (timeout: number) => `Transaction took more than ${timeout}s to process.`,
  PAYLOAD_VALIDATION_ERROR: (message: string) => message,
  PERMISSION_SUBJECT_INVALID: 'Permission subject is invalid',
  PERMISSION_RESOURCE_TRAVERSING_INVALID: "Permission resource can't traverse more than 2 levels above in the hierarchy",
  PERMISSION_RESOURCE_INVALID: 'Permission resource is invalid',
  OPERATION_NOT_GRANTED: (operation: string, resource?: string) => `Operation "${operation}"${resource ? `on resource ${resource}` : ''} not granted.`,
  ROLE_NOT_FOUND: 'Role not found',
  SERVICE_IS_UNDER_MAINTENANCE: 'Service is under maintenance',
  SHIPPER_NOT_FOUND: 'Shipper not found',
  SHIPMENT_IS_NOT_TRANSITING: 'Shipment is not transiting',
  SHIPMENT_IS_CANCELLED: 'Shipment is cancelled',
  SHIPMENT_IS_DRAFT: 'Shipment is in draft state',
  SHIPMENT_IS_DELIVERED: 'Shipment is already delivered',
  SHIPMENT_MISSING_GEOCODING: 'Shipment is missing geocoding.',
  SHIPMENT_NOT_FOUND: 'Shipment not found',
  SHIPMENT_STATUS_UNKNOW: 'Shipment status provided is unknown.',
  SSO_ONLY: 'This user can only login via SSO.',
  STORAGE_DELETE_NOT_ALLOWED: (storageName: string) => `Delete not allowed in the ${storageName} storage.`,
  STORAGE_DOWNLOAD_FAILED: (filenameOrURL: string, errorMessage: string) => `Download failed for ${filenameOrURL}: ${errorMessage}`,
  STORAGE_FAILED_TO_GET_PRESIGNED_URL: 'Failed to get presigned URL',
  STORAGE_FILE_ALREADY_EXISTS: (storageName: string) => `This file already exists in the ${storageName} storage.`,
  STORAGE_FILE_NOT_FOUND: (filename: string) => `Document ${filename} not found.`,
  TEC4CLOUD_CREDENTIALS_ARE_REQUIRED: 'Tec4Cloud credentials are required.',
  TEC4CLOUD_PROJECT_NOT_FOUND: (projectName: string) => `Project not found: ${projectName}`,
  TEC4CLOUD_SHIPMENT_NOT_FOUND: (shipmentExId: string) => `Shipment not found: ${shipmentExId}`,
  TOKEN_IS_REQUIRED: 'Token is required.',
  TOKEN_IS_INVALID: 'Token is invalid.',
  TRACKER_NOT_FOUND: 'Tracker not found',
  TRACKER_OR_PACKAGE_NOT_FOUND: 'Tracker or package not found',
  TRACKER_SMART_CONTRACT_NOT_FOUND: (trackerAddress: string) => `Tracker contract not found at address "${trackerAddress}"`,
  UNAUTHORIZED: 'Unauthorized.',
  USER_COMPANY_NAME_AND_NAME_MUST_BE_THE_SAME_FOR_GROUP: (username: string) => `Company name and user name must be the same for group users: ${username}`,
  USER_COMPANY_NAME_AND_NAME_MUST_BE_DIFFERENT_FOR_NON_GROUP: (username: string) => `Company name and user name cannot be the same for non-group users: ${username}`,
  USER_COMPANY_NAME_IS_REQUIRED: 'Company name is required',
  USER_CREATE_FAILED_DUE_TO_PERMISSIONS: (companyName: string) => `UserService.create failed: company ${companyName} not found, maybe due to permissions.`,
  USER_CREATION_FAILED: 'User creation failed',
  USER_EMAIL_ALREADY_IN_USE: 'Email already in use',
  USER_NOT_FOUND: 'User not found',
  USER_NOT_IN_GROUP: 'User is not in a group',
  USER_IS_NOT_A_GROUP: 'User is not a group',
  USER_IS_NOT_A_USER: 'User is not a user',
  USER_NOT_FOUND_BY_EX_ID: (exId: string) => `User not found for logistics_ex_id=${exId}.`,
  VALUE_MUST_BE_A_NUMBER: 'Value must be a number',
  VAULT_SMART_CONTRACT_FILE_NOT_FOUND: 'File not found in Vault smart contract',
  VAULT_SMART_CONTRACT_NOT_FOUND: (vaultAddress: string) => `Vault contract not found at address "${vaultAddress}"`,
  WALLET_NOT_FOUND: 'Wallet not found',
  ROLE_HAS_USERS: 'Role has users',
  GROUP_HAS_USERS: 'Group has users',
  GRANT_NOT_ALLOWED: 'Grant not allowed',
  HIERARCHY_LOOP: 'The provided owner_id will cause a loop in the hierarchy.',
} as const;

export type ErrorMessage = string;

export default ErrorMessages;
