import { POST } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import type { PermissionParsed } from 'types/Permission.ts';
import type { User } from 'types/User.ts';
import { Button } from 'ui/component/Button.tsx';
import { ISwap } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.js';
import { Input } from 'ui/control/Input.tsx';
import { Select } from 'ui/control/Select.tsx';

type RolePermissionFormProps = {
  onAdd: (values: PermissionParsed & { unparsed_permission: string }) => void;
};

const stringifyTemplate = (values: PermissionParsed) => {
  const { directive, subject, operation, resource, visibility } = values;
  const result = `Dir=${directive}; Sub=${subject}; Op=${operation}; Res=${resource}; Vis=${visibility}`;
  return result;
};

export const RolePermissionForm = ({ onAdd }: RolePermissionFormProps) => {
  const [resourceMode, setResourceMode] = React.useState<'select' | 'text'>('select');

  const { data: users } = useSWR<User[]>(['/user/list', { is_group: true, sort: [['name', 'asc']] }], POST);
  const { data: operations } = useSWR<string[]>(['/permission/list-operations', {}], POST);

  // todo: now only roles for groups can be created
  const availableSubjects = ['UserID/*'];

  const availableResources = React.useMemo(() => {
    if (!users) return {};
    const mappedUsers = users.reduce(
      (acc, user) => {
        acc[user.id + (user.is_group ? '/*' : '')] = user.name + (user.is_group ? '/*' : '');
        return acc;
      },
      {} as Record<string, string>,
    );
    return {
      '*': '*',
      'UserID/*': 'UserID/*',
      'OwnerID/*': 'OwnerID/*',
      'OwnerID/OwnerID/*': 'OwnerID/OwnerID/*',
      ...mappedUsers,
    };
  }, [users]);

  const formRef = React.useRef<HTMLFormElement>(null);
  const formContext = useForm<PermissionParsed>({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      directive: 'allow',
      subject: 'UserID/*',
      subject_is_group: true,
      operation: '*',
      resource: 'UserID/*',
      resource_is_owner: true,
      visibility: ['*'],
    },
  });
  const { handleSubmit, reset } = formContext;

  const onSubmit = async (values: PermissionParsed) => {
    void onAdd({
      ...values,
      unparsed_permission: stringifyTemplate(values),
    });
    reset();
  };

  const SwitchResourceModeButton = ({ text }: { text: string }) => (
    <div className="flex items-center gap-2" onClick={() => setResourceMode(resourceMode === 'select' ? 'text' : 'select')}>
      <span>
        {text}
        {resourceMode === 'text' ? ' ID' : ''}
      </span>
      <ISwap size={18} className="cursor-pointer rounded-md text-blue-600" />
    </div>
  );

  if (!operations) return <Spinner />;

  return (
    <FormProvider {...formContext}>
      <form id="search-form" ref={formRef} className="my-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="rounded-2xl bg-blue-sky p-8">
          <div className="flex items-end justify-between gap-4">
            <Input className="hidden" name="visibility" hidden={true} />
            <Input className="hidden" name="directive" hidden={true} />
            <Select
              className="w-[240px]"
              name={'subject'}
              label={'Subject'}
              placeholder={'Select Subject'}
              required={true}
              autoComplete={'off'}
              options={availableSubjects}
              registerOptions={{ required: true }}
            />
            <Select
              className="w-[240px]"
              name={'operation'}
              label="Operation"
              placeholder={'Select Operation'}
              required={true}
              autoComplete={'off'}
              options={operations}
              registerOptions={{ required: true }}
            />
            {resourceMode === 'select' ? (
              <Select
                className="w-[240px]"
                name={'resource'}
                label={<SwitchResourceModeButton text="Resource" />}
                placeholder={'Select Resource'}
                required={true}
                autoComplete={'off'}
                options={availableResources}
                registerOptions={{ required: true }}
              />
            ) : (
              <Input
                className="h-[52px] w-[240px]"
                name={'resource'}
                label={<SwitchResourceModeButton text="Resource" />}
                placeholder={'Enter Resource'}
                required={true}
                autoComplete={'off'}
                registerOptions={{ required: true }}
              />
            )}
            <Button className="blue h-[37px] max-w-60" type="submit">
              ADD
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
