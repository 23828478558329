import { MPOST } from 'common/helpers.ts';
import { FormProvider, useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { Button } from 'ui/component/Button.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Input } from 'ui/control/Input.tsx';

type Props = {
  onSave?: () => void;
  onClose?: () => void;
  isOpen: boolean;
};

type FormValues = {
  name: string;
  description: string;
};

export const RoleForm = ({ onSave, onClose, isOpen }: Props) => {
  const createRoleMutation = useSWRMutation('/role', MPOST);
  const isLoading = createRoleMutation.isMutating;

  const formMethods = useForm<FormValues>({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: false, // we need the default values to be applied in all cases
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      name: '',
      description: '',
    },
  });

  const { handleSubmit, formState, reset } = formMethods;

  const onSubmit = async (values: FormValues) => {
    await createRoleMutation.trigger(values);
    reset();
    if (onSave) onSave();
  };

  const handleClose = async () => {
    reset();
    if (onClose) onClose();
  };

  return (
    <Modal title={<h4>Role</h4>} isOpen={isOpen} onClose={handleClose}>
      <FormProvider {...formMethods}>
        <form className="flex max-h-[550px] w-[450px] flex-col gap-2.5 overflow-y-scroll p-2" onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6 flex flex-col gap-2">
            <Input name="name" placeholder="e.g: AGENT" label="Name" disabled={isLoading} registerOptions={{ required: true }}>
              {formState.errors.name?.type === 'required' && 'Field is required.'}
            </Input>
            <Input name="description" placeholder="e.g: Supports daily office operations and activities." label="Description" disabled={isLoading}>
              {formState.errors.name?.type === 'required' && 'Field is required.'}
            </Input>
          </div>

          <div className="mt-10 flex gap-5">
            <Button type="button" disabled={isLoading} className="blue-outlined" onClick={handleClose}>
              BACK
            </Button>
            <Button type="submit" disabled={!formState.isValid} loading={isLoading} className="blue flex items-center justify-center">
              CREATE ROLE
            </Button>
          </div>
          {createRoleMutation.error && <div className="red mt-2">{createRoleMutation.error}</div>}
        </form>
      </FormProvider>
    </Modal>
  );
};
