import { MPOST, POST, computeDistance, stringToPosition } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { Package } from 'types/Package.ts';
import type { Shipment } from 'types/Shipment.ts';
import type { Tracker } from 'types/Tracker.ts';
import { Button } from 'ui/component/Button.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Select } from 'ui/control/Select.tsx';

type Props = {
  shipment: Shipment;
  pkg: Package;
  onSave: (data: any) => void;
  onClose: () => void;
  isOpen: boolean;
};

export const TrackerAssignmentDialog: React.FC<Props> = ({ shipment, pkg, onSave, onClose, isOpen }) => {
  const { data: trackers, mutate: mutateTrackers } = useSWR<Tracker[]>(['/tracker/list', { sort: [['metrics_ex_id', 'asc']], shipment_id: shipment.id }], POST);

  const validTrackers = React.useMemo(() => {
    const validTrackers = { none: 'None' };
    if (!trackers) return validTrackers;
    const sortedTrackers = trackers.sort((a, b) => a.metrics_ex_id.localeCompare(b.metrics_ex_id));
    for (const tracker of sortedTrackers) {
      if (tracker.package_id && tracker.package_id !== pkg.id) continue;
      validTrackers[tracker.id] = `${tracker.metrics_ex_id} (${tracker.description})`.toUpperCase();
    }
    return validTrackers;
  }, [trackers, pkg]);

  const formMethods = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      trackerId: pkg.tracker?.id || 'none',
    },
  });

  const { handleSubmit, formState, watch } = formMethods;
  const selectedId = watch('trackerId');
  const { trigger, isMutating, error } = useSWRMutation(selectedId === 'none' ? '/tracker/unassign' : '/tracker/assign', MPOST);

  const handleSave = async ({ trackerId }) => {
    // confirm unassignment of current tracker
    if (trackerId === 'none' && !window.confirm('Are you sure you want to unassign the tracker?')) return;

    // before assigning new tracker, run some checks
    if (trackerId !== 'none') {
      const targetTracker = trackers?.find((tracker) => tracker.id === trackerId) as Tracker;
      if (!targetTracker || !trackers) return;

      const msg1 = 'The selected tracker has never transmitted any metric.\nAre you sure you want to assign this tracker?';
      if (!targetTracker.last_measured_at && !window.confirm(msg1)) return;

      const lastMetricAgeInHours = !targetTracker.last_measured_at
        ? Number.POSITIVE_INFINITY
        : (new Date().getTime() - new Date(targetTracker.last_measured_at).getTime()) / 3600_000;
      const msg2 = 'The selected tracker has not provided its position in the last 24h.\nAre you sure you want to assign this tracker?';
      if (lastMetricAgeInHours > 24 && !window.confirm(msg2)) return;

      const sameShipmentTrackers = trackers.filter((tracker) => tracker.shipment_id === pkg.shipment_id);
      for (const tracker of sameShipmentTrackers) {
        if (!targetTracker?.position) continue;
        if (!tracker?.position) continue;

        // confirm assignment if trackers are more than 100 meters apart
        const distance = computeDistance(stringToPosition(targetTracker.position), stringToPosition(tracker.position));
        if (distance > 100) {
          const confirmMessage =
            'The last position of the selected tracker is more than 100 meters away from another tracker in the same shipment.\nAre you sure you want to assign this tracker?';
          if (!window.confirm(confirmMessage)) return;
          else break;
        }
      }
    }

    await trigger(selectedId === 'none' ? { tracker_id: pkg.tracker?.id } : { tracker_id: trackerId, package_id: pkg.id });
    void mutateTrackers();
    onSave({ tracker_id: trackerId, package_id: pkg.id });
  };

  if (trackers === undefined) return <Spinner centered={true} />;

  return (
    <Modal key={pkg.id} title={<h4>Add Tracker</h4>} isOpen={isOpen} onClose={onClose}>
      <FormProvider {...formMethods}>
        <form className="flex w-[450px] flex-col gap-2.5" onSubmit={handleSubmit(handleSave)}>
          <div className="grid grid-cols-[100px_min-content] gap-2">
            <span>Order no:</span>
            <span>{shipment.logistics_ex_id}</span>
            <span>Package ID:</span>
            <span>{pkg.logistics_ex_id}</span>
          </div>
          <div className="mt-6 flex flex-col gap-2">
            <Select name={'trackerId'} label={'Tracker ID'} options={validTrackers} registerOptions={{ required: true }} />
          </div>

          <div className="mt-10 flex gap-5">
            <Button disabled={isMutating} className="blue-outlined" onClick={onClose}>
              BACK
            </Button>
            <Button
              type="submit"
              disabled={formState.isSubmitting || !formState.isValid || !formState.isDirty}
              loading={formState.isSubmitting}
              className="blue flex items-center justify-center"
            >
              Save
            </Button>
          </div>
          {error && <span className="text-center text-red-500">An error occurred while saving. Please try again.</span>}
        </form>
      </FormProvider>
    </Modal>
  );
};
