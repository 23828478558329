import type { AbstractSigner } from 'ethers';
import { type ContractRunner, FunctionFragment, type Signer, Wallet, isBytesLike } from 'ethers';
// ts paths should not be used here as hardhat does not support them when using esm modules
import { BaseError, errorCodes, errorMessages } from '../../common/src/errors/index.ts';
import AuthArtifact from '../artifacts/contracts/Auth.sol/Auth.json' with { type: 'json' };
import TrackerArtifact from '../artifacts/contracts/Tracker.sol/Tracker.json' with { type: 'json' };
import VaultArtifact from '../artifacts/contracts/Vault.sol/Vault.json' with { type: 'json' };
import type { Auth, OrganisationControl, Tracker, Vault } from '../typechain-types/contracts/index.ts';
import BlockchainError from './BlockchainError.ts';
import BrowserNonceManager from './NonceManager.ts';
import SDContract from './SDContract.ts';
import SDRPCProvider from './SDRPCProvider.ts';
import type { BCAlert, BCMetric } from './enums.ts';

/**
 * The following block has 3 different issues:
 * - vite: we need to import the RedisNonceManager only for Node environment
 * - vitest: when running tests, import.meta.env is populated by vitest thus we are deleting it from the process.env there
 * We should consider moving everything to an async import run, maybe run in an init function of this class.
 */

// by default, we use the default NonceManager exported by ethers (eg: on browser)
let DefaultNonceManager: new (...args: any[]) => AbstractSigner;
// @ts-ignore: if on node, we use the RedisNonceManager and with the following condition we avoid Vite from bundling this code block at all
if (import.meta.env?.VITE_APP_ENV === undefined) {
  const { default: RedisNonceManager } = await import('./RedisNonceManager.ts');
  DefaultNonceManager = RedisNonceManager;
} else {
  // don't change this line, or SW will break since it will not understand that this file must be downloaded and cached
  DefaultNonceManager = BrowserNonceManager;
}

// tofix: have this generated dynamically
export const BlockchainErrorNames = {
  AuthUserNotFound: 'AuthUserNotFound',
  AuthUserEmailNotFound: 'AuthUserEmailNotFound',
  TrackerShipmentNotFound: 'TrackerShipmentNotFound',
};

export type { BCAlert as Alert, Auth, BCMetric as Metric, OrganisationControl, Tracker, Vault };

const applyBlockchainErrorHandler = (instance: SDContract) => {
  instance.connect = new Proxy(instance.connect, {
    apply: (target, thisArg, argumentsList) => {
      const newInstance = Reflect.apply(target, thisArg, argumentsList);
      applyBlockchainErrorHandler(newInstance);
      return newInstance;
    },
  });

  for (const f of instance.interface.fragments) {
    if (!(f instanceof FunctionFragment)) continue;
    instance[f.name] = new Proxy(instance[f.name], {
      apply: async (target, thisArg, argumentsList) => {
        try {
          return await Reflect.apply(target, thisArg, argumentsList);
        } catch (error: any) {
          if (error instanceof BlockchainError) throw error;
          if (!error.data || !isBytesLike(error.data)) throw error;
          const parsedError = instance.interface.parseError(error.data);
          if (!parsedError) throw error;
          throw new BlockchainError(parsedError);
        }
      },
    });
  }
};

export class BlockchainConnector {
  private provider: SDRPCProvider;
  private authAddress?: string;
  private trackerAddress?: string;
  private vaultAddress?: string;

  constructor(rpcUrlOrProvider: string | SDRPCProvider, contractAddresses: { trackerAddress?: string; vaultAddress?: string; authAddress?: string } = {}) {
    this.provider = typeof rpcUrlOrProvider === 'string' ? new SDRPCProvider(rpcUrlOrProvider) : rpcUrlOrProvider;
    const { trackerAddress, vaultAddress, authAddress } = contractAddresses;
    this.authAddress = authAddress;
    this.trackerAddress = trackerAddress;
    this.vaultAddress = vaultAddress;
  }

  private setSigner(signer: Signer) {
    if (!signer.signMessage) return;
    this.provider.setSigner(signer);
  }

  public getProvider() {
    return this.provider;
  }

  public setAuthAddress(address: string) {
    this.authAddress = address;
  }

  public setTrackerAddress(address: string) {
    this.trackerAddress = address;
  }

  public setVaultAddress(address: string) {
    this.vaultAddress = address;
  }

  public getAuthInstance(runner?: ContractRunner | Signer) {
    if (!this.authAddress) throw new Error('Auth address not set');
    const auth = new SDContract(this.authAddress, AuthArtifact.abi, this.provider, runner);
    applyBlockchainErrorHandler(auth);
    if (runner) this.setSigner(runner as Signer);
    return auth as unknown as Auth;
  }

  public getTrackerInstance(runner?: ContractRunner | Signer) {
    if (!this.trackerAddress) throw new Error('Tracker address not set');
    const tracker = new SDContract(this.trackerAddress, TrackerArtifact.abi, this.provider, runner);
    applyBlockchainErrorHandler(tracker);
    if (runner) this.setSigner(runner as Signer);
    return tracker as unknown as Tracker;
  }

  public getVaultInstance(runner?: ContractRunner | Signer) {
    if (!this.vaultAddress) throw new Error('Vault address not set');
    const vault = new SDContract(this.vaultAddress, VaultArtifact.abi, this.provider, runner);
    applyBlockchainErrorHandler(vault);
    if (runner) this.setSigner(runner as Signer);
    return vault as unknown as Vault;
  }

  public async verifyContractExists(address: string) {
    const contractCode = await this.getProvider().getCode(address);
    if (contractCode === '0x') throw new BaseError(errorCodes.COMMON_SMART_CONTRACT_NOT_FOUND, errorMessages.SMART_CONTRACT_NOT_FOUND(address));
  }

  public createWallet(privateKey: string) {
    const wallet = new Wallet(privateKey, this.provider);
    return new DefaultNonceManager(wallet);
  }

  public createRandomWallet() {
    const wallet = Wallet.createRandom(this.provider);
    const privateKey = wallet.privateKey;
    return { wallet: new DefaultNonceManager(wallet), privateKey };
  }
}

export { BlockchainError };
