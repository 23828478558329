import cx from 'clsx';
import React from 'react';

type Props = {
  className?: string;
  tabs: Record<string, string>;
  defaultTab: string;
  onChange: (x: string | number) => void | Promise<void>;
};

export const Tab: React.FC<Props> = ({ className, tabs, defaultTab, onChange, ...otherProps }) => {
  const [current, setCurrent] = React.useState<string>(defaultTab);

  const onClick = (key: string) => () => {
    setCurrent(key);
    void onChange(key);
  };

  return (
    <div className={cx('scrollbar-hide flex w-full flex-row overflow-x-auto', className)} {...otherProps}>
      {Object.entries(tabs).map(([key, label]) => (
        <span
          key={key}
          className={cx(
            'cursor-pointer text-nowrap border-b-2 p-4 pb-2 text-center transition-colors duration-300',
            key !== current && 'border-b-gray-300',
            key === current && 'border-b-blue text-blue',
          )}
          onClick={onClick(key)}
        >
          {label}
        </span>
      ))}
      <span className="flex-grow border-b-2 border-b-gray-300" />
    </div>
  );
};
