import { GET, MPOST, processT4CProjectNames } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { Button } from 'ui/component/Button.tsx';
import { ICheckmarkCircle, IError, IOpenLink } from 'ui/component/Icons.tsx';
import { Input } from 'ui/control/Input.tsx';
import { TextArea } from 'ui/control/TextArea.tsx';

type CredentialsFormValues = {
  email: string;
  password: string;
};

type ProjectNamesFormValues = {
  project_names: string;
};

type ProjectNamesCheckResult = {
  valid: boolean;
  invalidProjects: string[];
  invalidUsers: string[];
};

const CredentialsSection = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [checkResult, setCheckResult] = React.useState<boolean | null>(null);

  const dataSwr = useSWR(['/system-settings/tec4cloud-credentials'], GET);

  const defaultValues: CredentialsFormValues = React.useMemo(
    () => ({
      email: dataSwr.data ? dataSwr.data.email : '',
      password: dataSwr.data ? dataSwr.data.password : '',
    }),
    [dataSwr.data],
  );

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues,
  });
  const { handleSubmit, formState, watch } = formContext;

  const checkMutation = useSWRMutation('/system-settings/check-tec4cloud-credentials', MPOST);
  const saveMutation = useSWRMutation('/system-settings/tec4cloud-credentials', MPOST);

  const error = React.useMemo(() => {
    if (!dataSwr.error && !checkMutation.error && !saveMutation.error) return null;
    return dataSwr.error || checkMutation.error || saveMutation.error;
  }, [dataSwr.error, checkMutation.error, saveMutation.error]);

  const [email, password] = watch(['email', 'password']);

  const onCheck = async () => {
    const result = await checkMutation.trigger({ email, password });
    setCheckResult(result);
  };

  const onSubmit = async ({ email, password }: CredentialsFormValues) => {
    const result = await saveMutation.trigger({ email, password });
    setCheckResult(result ? null : false);
  };

  return (
    <section className="mt-4 rounded-2xl bg-white p-8">
      <div className="grid grid-cols-2 gap-12">
        <div className="flex flex-col gap-4">
          <h4>Credentials</h4>
          <p>Provide the Tec4Cloud credentials to use by ATLAS when managing shipments, trackers and alerts.</p>
          <p>
            You can use the <strong>Check</strong> button to validate them before saving.
          </p>
        </div>
        <FormProvider {...formContext}>
          <form id="search-form" ref={formRef} className="my-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex flex-col gap-6 p-1">
              <Input
                label="Email"
                name={'email'}
                placeholder={'example@company.com'}
                registerOptions={{
                  required: true,
                }}
                autoFocus={true}
              >
                {formState.errors.email?.type === 'required' && <div className="text-red">Field is required</div>}
              </Input>
              <Input
                label="Password"
                name={'password'}
                type="password"
                registerOptions={{
                  required: true,
                }}
                autoFocus={true}
              >
                {formState.errors.password?.type === 'required' && <div className="text-red">Field is required</div>}
              </Input>
              {checkResult === true && (
                <div className="flex flex-row items-center gap-2 font-bold text-green">
                  <span>The credentials are valid</span> <ICheckmarkCircle size={22} className="green" />
                </div>
              )}
              {checkResult === false && (
                <div className="flex flex-row items-center gap-2 font-bold text-red">
                  <span>The credentials are invalid</span> <IError size={22} className="red" />
                </div>
              )}
              <div className="flex flex-row gap-4">
                <Button loading={dataSwr.isLoading || checkMutation.isMutating} disabled={saveMutation.isMutating} onClick={onCheck} type="button" className="blue-outlined">
                  Check
                </Button>
                <Button type="submit" loading={dataSwr.isLoading || saveMutation.isMutating} disabled={checkMutation.isMutating} className="blue">
                  Save
                </Button>
              </div>
              {error && <p className="text-red">Error: {error.message || 'An error occurred'}</p>}
            </div>
          </form>
        </FormProvider>
      </div>
    </section>
  );
};

const ProjectNamesSection = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [checkResult, setCheckResult] = React.useState<ProjectNamesCheckResult | null>(null);

  const dataSwr = useSWR(['/system-settings/tec4cloud-projects'], GET);

  const defaultValues: ProjectNamesFormValues = React.useMemo(
    () => ({
      project_names: dataSwr.data ? dataSwr.data.join(', ') : '',
    }),
    [dataSwr.data],
  );

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues,
  });
  const { handleSubmit, watch } = formContext;

  const checkMutation = useSWRMutation('/system-settings/check-tec4cloud-projects', MPOST);
  const saveMutation = useSWRMutation('/system-settings/tec4cloud-projects', MPOST);

  const error = React.useMemo(() => {
    if (!dataSwr.error && !checkMutation.error && !saveMutation.error) return null;
    return dataSwr.error || checkMutation.error || saveMutation.error;
  }, [dataSwr.error, checkMutation.error, saveMutation.error]);

  const project_names = watch('project_names');

  const onCheck = async () => {
    if (!project_names) return;
    const payload = processT4CProjectNames(project_names);
    const result = await checkMutation.trigger({
      project_names: payload,
    });
    setCheckResult(result);
  };

  const onSubmit = async (values: ProjectNamesFormValues) => {
    if (!values.project_names) return;
    const payload = processT4CProjectNames(project_names);
    const result = await saveMutation.trigger({
      project_names: payload,
    });
    setCheckResult(result.valid ? null : result);
  };

  return (
    <section className="mt-4 rounded-2xl bg-white p-8">
      <div className="grid grid-cols-2 gap-12">
        <div className="flex flex-col gap-4">
          <h4>Project Names</h4>
          <p>Provide a comma-separated (or return-line separated) list of existing Tec4Cloud project names you want to link to users on Atlas Platform.</p>
          <p className="font-bold">A user with the same name as the project must exist on the Atlas Platform, and he should be the owner of the shipments as well.</p>
          <p>
            You can use the <strong>Check</strong> button to validate them before saving.
          </p>
          <a href="https://tracking.tec4med.com/project" target="_blank" rel="noreferrer" className="justify-left flex flex-row items-center gap-2 text-blue">
            <span>Tec4Cloud Projects page</span>
            <IOpenLink />
          </a>
        </div>
        <FormProvider {...formContext}>
          <form id="search-form" ref={formRef} className="my-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="flex flex-col gap-6 p-1">
              <TextArea label="Project Names" name={'project_names'} placeholder="OFFICE_A, OFFICE_B, OFFICE_C" registerOptions={{ required: true }} rows={15}>
                {formContext.formState.errors.project_names?.type === 'required' && <div className="text-red">Field is required</div>}
              </TextArea>
              {checkResult && checkResult.valid === true && (
                <div className="flex flex-row items-center gap-2 font-bold text-green">
                  <span>The project names are valid</span> <ICheckmarkCircle size={22} className="green" />
                </div>
              )}
              {checkResult && checkResult.valid === false && (
                <div className="flex flex-col gap-2 text-red">
                  <div className="flex flex-row items-center gap-2 font-bold">
                    <span>The project names are invalid</span> <IError size={22} className="red" />
                  </div>
                  {checkResult.invalidProjects.length > 0 && (
                    <div>
                      <span>Project(s) not found: {checkResult.invalidProjects.join(', ')}</span>
                    </div>
                  )}
                  {checkResult.invalidUsers.length > 0 && (
                    <div>
                      <span>User(s) not found: {checkResult.invalidUsers.join(', ')}</span>
                    </div>
                  )}
                </div>
              )}
              <div className="flex flex-row gap-4">
                <Button loading={dataSwr.isLoading || checkMutation.isMutating} disabled={saveMutation.isMutating} onClick={onCheck} type="button" className="blue-outlined">
                  Check
                </Button>
                <Button type="submit" loading={dataSwr.isLoading || saveMutation.isMutating} disabled={checkMutation.isMutating} className="blue">
                  Save
                </Button>
              </div>
              {error && <p className="text-red">Error: {error.message || 'An error occurred'}</p>}
            </div>
          </form>
        </FormProvider>
      </div>
    </section>
  );
};

export const Tec4CloudConfig = () => {
  return (
    <main className="view-container">
      <section className="flex flex-row place-content-between items-baseline">
        <h4>Tec4Cloud Setup</h4>
      </section>

      <CredentialsSection />
      <ProjectNamesSection />
    </main>
  );
};
