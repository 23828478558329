import React from 'react';
import ReactDOM from 'react-dom';

export const BodyPortal = ({ children }) => {
  const element = React.useRef(document.createElement('div'));

  React.useEffect(() => {
    const node = element.current;
    node.classList.add('body-portal');
    document.body.appendChild(node);
    return () => {
      document.body.removeChild(node);
    };
  }, []);

  return ReactDOM.createPortal(children, element.current);
};
