import { MPOST, POST, truncate } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { Leg } from 'types/Leg.ts';
import type { Shipment } from 'types/Shipment.ts';
import type { User } from 'types/User.ts';
import { Button } from 'ui/component/Button.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Select } from 'ui/control/Select.tsx';

type Props = {
  shipment: Shipment;
  leg: Leg;
  onSave?: (data: { supplier_id: string; leg_id: string }) => void;
  onClose: () => void;
  isOpen: boolean;
};

export const SupplierAssignmentDialog: React.FC<Props> = ({ shipment, leg, onSave, onClose, isOpen }) => {
  const { data: suppliers, mutate: mutateSuppliers, isLoading } = useSWR<User[]>(['/user/list', { role: 'SUPPLIER', sort: [['name', 'asc']] }], POST);

  const validSuppliers = React.useMemo(() => {
    const validSuppliers = { none: 'None' };
    if (!suppliers) return validSuppliers;
    for (const supplier of suppliers) {
      const name = supplier.name.toUpperCase();
      const id = truncate(supplier.id, 8, '').toUpperCase();
      validSuppliers[supplier.id] = `${name} - ${id}`;
    }
    return validSuppliers;
  }, [suppliers]);

  const formMethods = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      supplierId: leg.agent_id || 'none',
    },
  });

  const { handleSubmit, formState } = formMethods;
  const { trigger: triggerAssign, isMutating: isMutatingAssign, error: errorAssign } = useSWRMutation(`/leg/${leg.id}/assign-supplier`, MPOST);
  const { trigger: triggerUnassign, isMutating: isMutatingUnassign, error: errorUnassign } = useSWRMutation(`/leg/${leg.id}/unassign-supplier`, MPOST);

  const isMutating = isMutatingAssign || isMutatingUnassign;
  const error = errorAssign || errorUnassign;

  const onSubmit = async ({ supplierId: selectedSupplierId }) => {
    if (!suppliers) return;
    const currentSupplier = suppliers.find((user) => user.id === leg.agent_id);

    // NO CHANGE, DO NOTHING
    if (currentSupplier && currentSupplier.id === selectedSupplierId) {
      onClose();
      return;
    }

    // UNASSIGN
    if (selectedSupplierId === 'none' && window.confirm('Are you sure you want to unassign the current supplier?')) {
      await triggerUnassign({});
      void mutateSuppliers();
      return onSave?.({ supplier_id: 'none', leg_id: leg.id });
    }

    // ASSIGN
    const targetSupplier = suppliers.find((user) => user.id === selectedSupplierId) as User;
    if (!targetSupplier) return;

    await triggerUnassign({});
    await triggerAssign({ supplier_id: selectedSupplierId });

    void mutateSuppliers();
    onSave?.({ supplier_id: selectedSupplierId, leg_id: leg.id });
  };

  if (isLoading) return <Spinner centered={true} />;

  return (
    <Modal key={leg.id} title={<h4>Assign Supplier</h4>} isOpen={isOpen} onClose={onClose}>
      <FormProvider {...formMethods}>
        <form className="flex w-[450px] flex-col gap-2.5" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-[100px_min-content] gap-2">
            <span>Order no:</span>
            <span>{shipment.logistics_ex_id}</span>
            <span>Leg ID:</span>
            <span>{leg.logistics_ex_id}</span>
          </div>
          <div className="mt-6 flex flex-col gap-2">
            <Select name={'supplierId'} label={'Supplier ID'} options={validSuppliers} registerOptions={{ required: true }} />
          </div>

          <div className="mt-10 flex gap-5">
            <Button disabled={isMutating} className="blue-outlined" onClick={onClose}>
              BACK
            </Button>
            <Button
              type="submit"
              disabled={formState.isSubmitting || !formState.isValid || !formState.isDirty}
              loading={formState.isSubmitting}
              className="blue flex items-center justify-center"
            >
              Save
            </Button>
          </div>
          {error && (
            <span className="text-center text-red-500">
              {error && typeof error?.response?.data === 'string' ? error.response.data : 'An error occurred while saving. Please try again.'}
            </span>
          )}
        </form>
      </FormProvider>
    </Modal>
  );
};
