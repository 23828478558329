import type React from 'react';
import { Button } from 'ui/component/Button.tsx';
import { IArrowBack, ICheckmarkCircle } from 'ui/component/Icons.tsx';
import type { VerificationResult } from '#admin/hook/useVerifyDocument.ts';
import { VerificationCard } from './VerificationCard.tsx';

type Props = {
  verificationResult: VerificationResult;
  onBack: () => void;
};

export const DocumentVerified: React.FC<Props> = ({ verificationResult, onBack }) => {
  return (
    <div className="flex w-full max-w-[55rem] flex-col items-center justify-center gap-8 p-8">
      <div className="flex size-[9.25rem] flex-row items-center justify-center rounded-full bg-green-light p-6">
        <ICheckmarkCircle className="size-full text-green" />
      </div>
      <h1 className="text-grey-body">Document is verified</h1>
      <VerificationCard document={verificationResult} />
      <div className="flex w-full flex-row" onClick={onBack}>
        <Button className="blue-outlined !w-fit" LeftIcon={IArrowBack}>
          Back
        </Button>
      </div>
    </div>
  );
};
