import { isGranted } from 'common/helpers.ts';
import type { OPERATION } from 'types/Permission.ts';
import { useMe } from '#admin/hook/useMe.tsx';

export const usePermission = (operation: OPERATION): undefined | boolean => {
  const me = useMe();
  if (!me) return undefined;

  const granted = isGranted(me, operation);
  return granted;
};
