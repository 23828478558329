import type { EnvControllerGetResponse } from 'api/env/Env.schema.ts';
import { BaseError, errorCodes, errorMessages } from 'common/errors/index.ts';
import { addHexPrefix, getFilenameFromUrl, isNetworkError, parseDocumentInfo } from 'common/helpers.ts';
import React from 'react';
import useBlockchain from './useBlockchain.ts';

export type DocumentInfo = {
  filename: string;
  url: string;
  encryptionKey: string;
  metadata?: Record<string, string>;
  type: string;
  originalUrl: string;
};

export type DocumentResult = {
  info?: DocumentInfo;
  error?: Error;
};

export default function useFetchDocuments(env: EnvControllerGetResponse | undefined, urls: string[]) {
  const [results, setResults] = React.useState<DocumentResult[]>([]);
  const [isFetching, setIsFetching] = React.useState<boolean>(true);
  const [error, setError] = React.useState<Error | null>(null);

  const success = React.useMemo(() => results.filter(({ error, info }) => !error && info).map(({ info }) => info) as DocumentInfo[], [results]);
  const networkError = isNetworkError(error);

  const hashString = React.useCallback((url: string) => {
    const hashString = getFilenameFromUrl(url)?.split('/').pop() ?? '';
    return hashString;
  }, []);

  const { vault } = useBlockchain(env);

  const fetchDocument = React.useCallback(
    async (url: string) => {
      const hash = hashString(url);
      if (!vault) return {};

      try {
        const document = await vault.getDocument(addHexPrefix(hash));
        if (!document) throw new BaseError(errorCodes.DOCUMENT_NOT_FOUND, errorMessages.DOCUMENT_NOT_FOUND);
        const info = parseDocumentInfo(document);
        return { info };
      } catch (errorThrown: any) {
        console.error(errorThrown);
        const errorMessage =
          errorThrown?.message?.toLowerCase() === 'failed to fetch'
            ? errorThrown?.message
            : errorThrown?.errorName || errorThrown?.name || 'An error occurred while fetching the document';
        const error = new BaseError(errorCodes.VAULT_SMART_CONTRACT_ERROR_GETTING_DOCUMENT, errorMessage);
        return { error };
      }
    },
    [vault, hashString],
  );

  const fetchDocuments = React.useCallback(async () => {
    setIsFetching(true);
    setResults([]);
    setError(null);
    const results: DocumentResult[] = [];
    if (!vault) return;

    for (const url of urls) {
      const { error, info } = await fetchDocument(url);
      const networkError = isNetworkError(error);
      if (networkError && error) {
        setError(error);
      }
      if (error || info) {
        results.push({ error, info });
      }
    }
    setResults(results);
    setIsFetching(false);
  }, [fetchDocument, vault, urls]);

  React.useEffect(() => {
    void fetchDocuments();
  }, [fetchDocuments]);

  return { results, isFetching, refresh: fetchDocuments, success, error, networkError };
}
