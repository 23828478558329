import type { ColumnDef, SortingState } from '@tanstack/react-table';
import axios from 'axios';
import cx from 'clsx';
import { GET, MPOST, MPUT, isSuperadmin } from 'common/helpers.ts';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { PermissionParsed } from 'types/Permission.ts';
import type { Role as TRole } from 'types/Role.ts';
import { Breadcrumb } from 'ui/component/Breadcrumb.tsx';
import { Button } from 'ui/component/Button.tsx';
import { IDelete, IDeleteOutline, IRefresh } from 'ui/component/Icons.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { RolePermissionForm } from '#admin/component/RolePermissionForm.tsx';
import { Table } from '#admin/component/Table.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

export const getColumns = (onDelete: (permission: PermissionParsed & { unparsed_template: string }) => void) => {
  const columns: ColumnDef<PermissionParsed & { unparsed_template: string }>[] = [
    {
      accessorKey: 'subject',
      header: 'Subject',
      size: 100,
      cell: (info) => {
        const { subject, subject_is_group } = info.row.original;
        const value = subject + (subject_is_group ? '/*' : '');
        return value;
      },
    },
    {
      accessorKey: 'operation',
      header: 'Operation',
      size: 200,
    },
    {
      accessorKey: 'resource',
      header: 'Resource',
      size: 350,
      cell(info) {
        const { resource, resource_is_owner } = info.row.original;
        const value = resource + (resource_is_owner ? '/*' : '');
        return value;
      },
    },
    {
      header: 'Actions',
      size: 70,
      cell: (info) => (
        <div className="flex w-full items-center justify-center">
          <IDelete
            className="text-gray-500"
            size={20}
            onClick={() => {
              if (onDelete) onDelete(info.row.original);
            }}
          />
        </div>
      ),
    },
  ];

  return columns;
};

export const Role = () => {
  const { id: roleId } = useParams();
  const { data: role, mutate: mutateRole, isLoading } = useSWR<TRole>([`/role/${roleId}`], GET, { keepPreviousData: true });
  const updateRoleMutation = useSWRMutation(role && `/role/${role.id}`, MPUT);

  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'operation', desc: false }]);
  const me = useMe();

  const reassignAllMutation = useSWRMutation('/role/reassign-all', MPOST);

  const rows = React.useMemo(() => {
    if (!role) return [];
    const rows = role.permission_templates_parsed.map((parsed_permission, i) => ({ ...parsed_permission, unparsed_template: role.permission_templates[i] }));
    return rows;
  }, [role]);

  const columns = React.useMemo(
    () =>
      getColumns(async (permission) => {
        if (!role) return;
        if (!confirm('Are you sure you want to delete this permission template?')) return;
        await updateRoleMutation.trigger({
          id: role.id,
          permission_templates: role.permission_templates.filter((template) => template !== permission.unparsed_template),
        });
        void mutateRole();
      }),
    [role, updateRoleMutation, mutateRole],
  );

  const handleDeleteRole = React.useCallback(async () => {
    if (!confirm('Are you sure you want to delete this role?')) return;
    await axios.delete(`/role/${roleId}`);
    window.location.href = '/roles';
  }, [roleId]);

  const handleReassignAll = React.useCallback(async () => {
    if (!role) return;
    await reassignAllMutation.trigger({ name: role.name });
    void mutateRole();
  }, [role, reassignAllMutation, mutateRole]);

  const handleAddPermission = async (values: PermissionParsed & { unparsed_permission: string }) => {
    if (!role) return;
    const newPermissionTemplates = Array.from(new Set([...role.permission_templates, values.unparsed_permission]));
    await updateRoleMutation.trigger({ id: role.id, permission_templates: newPermissionTemplates });
    void mutateRole();
  };

  if (!me || isLoading || !role) return <Spinner centered={true} />;

  return (
    <main className="view-container">
      <Breadcrumb className="mb-6" items={[{ label: 'Roles', path: '/roles' }, { label: 'Role details' }]} />
      <section className="flex flex-row place-content-between items-baseline">
        <h4>{role.name}</h4>
        <div className="flex flex-row gap-4">
          {isSuperadmin(me) && (
            <Button className="blue-outlined" LeftIcon={IRefresh} onClick={handleReassignAll} loading={reassignAllMutation.isMutating}>
              REASSIGN ALL
            </Button>
          )}

          {isSuperadmin(me) && (
            <Button className="red-outlined" LeftIcon={IDeleteOutline} onClick={handleDeleteRole}>
              DELETE ROLE
            </Button>
          )}
        </div>
      </section>

      <section className="mt-4 rounded-2xl bg-white p-8 pb-4">
        <h6>Permission Templates</h6>

        <RolePermissionForm onAdd={handleAddPermission} />

        <Table className={cx('max-h-[calc(100vh-385px)]')} columns={columns} items={rows} defaultSorting={sorters} onColumnSort={setSorters} estimateSize={50} overscan={50} />
      </section>
    </main>
  );
};
