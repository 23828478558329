import * as Sentry from '@sentry/react';
import type { AuthMeResponse } from 'api/auth/Auth.schemas.ts';
import { AxiosError } from 'axios';
import React from 'react';
import { useDispatch } from 'react-redux';
import axios from '#admin/axios.ts';
import { useBlockchain } from '#admin/hook/useBlockchain.tsx';
import { useMe } from '#admin/hook/useMe.tsx';
import { actions } from '#admin/reducer/app.ts';

type Props = {
  timeout?: number;
};

export const AuthWatcher: React.FC<Props> = ({ timeout = 60_000 }) => {
  const me = useMe();
  const dispatch = useDispatch();
  const { wallet, auth } = useBlockchain();

  const authenticate = React.useCallback(async () => {
    // check if authenticated on api
    try {
      await axios.get<AuthMeResponse>('/auth/me', { timeout: timeout - 1_000 });
    } catch (error) {
      if (error instanceof AxiosError) {
        const forbidden = error.response?.status === 401;
        const maintenance = error.response?.status === 503;
        if (forbidden || maintenance) dispatch(actions.logout());
      }
      console.error(error);
      Sentry.captureException(error);
    }

    // check if authenticated on blockchain
    if (auth && wallet && me) {
      const authenticated = await auth.isUserLoggedInWithAddress(me.id);
      if (!authenticated) dispatch(actions.logout());
    }
  }, [dispatch, me, wallet, auth, timeout]);

  React.useEffect(() => {
    if (!me) return;
    const intervalHandler = window.setInterval(authenticate, timeout);
    return () => {
      intervalHandler && window.clearInterval(intervalHandler);
    };
  }, [me, authenticate, timeout]);

  return null;
};
