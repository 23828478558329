import type { EnvControllerGetResponse } from 'api/env/Env.schema.ts';
import type SDRPCProvider from 'blockchain/SDRPCProvider.ts';
import { BlockchainConnector } from 'blockchain/connector.ts';
import AuthSmartContract from 'common/blockchain/AuthSmartContract.ts';
import VaultSmartContract from 'common/blockchain/VaultSmartContract.ts';
import Wallet from 'common/blockchain/Wallet.ts';
import { BaseError, errorCodes, errorMessages } from 'common/errors/index.ts';
import React from 'react';

export default function useBlockchain(env?: EnvControllerGetResponse | null) {
  const blockchainConnector = React.useMemo(() => {
    if (!env) return null;
    return new BlockchainConnector(env.SD_RPC_URL, {
      authAddress: env.AUTH_SMART_CONTRACT_ADDRESS,
      trackerAddress: env.TRACKER_SMART_CONTRACT_ADDRESS,
      vaultAddress: env.VAULT_SMART_CONTRACT_ADDRESS,
    });
  }, [env]);

  const vault = React.useMemo(() => {
    if (!env || !blockchainConnector) return null;
    return new VaultSmartContract(blockchainConnector);
  }, [env, blockchainConnector]);

  const auth = React.useMemo(() => {
    if (!env || !blockchainConnector) return null;
    return new AuthSmartContract(blockchainConnector, env.SUPERADMIN_GROUP_ID);
  }, [env, blockchainConnector]);

  const wallet = React.useMemo(() => {
    if (!blockchainConnector) return null;
    return new Wallet(blockchainConnector);
  }, [blockchainConnector]);

  const getCurrentBlockTimestamp = React.useCallback(async () => {
    if (!blockchainConnector) return null;
    const provider = blockchainConnector.getProvider() as SDRPCProvider & { getBlock: (blockNumber: string | number) => Promise<{ timestamp: number }> };
    // getBlock really exists in the SDRPCProvider. However, TypeScript does not know about it.
    const block = await provider.getBlock('latest');
    if (!block) throw new BaseError(errorCodes.CURRENT_BLOCK_NOT_FOUND, errorMessages.CURRENT_BLOCK_NOT_FOUND);
    return block.timestamp;
  }, [blockchainConnector]);

  return { blockchainConnector, getCurrentBlockTimestamp, auth, vault, wallet };
}
