import debounce from 'awesome-debounce-promise';
import { GET, MPOST, MPUT, POST, isValidEmail } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { ROLE, Role } from 'types/Role.ts';
import type { User } from 'types/User.ts';
import { Button } from 'ui/component/Button.tsx';
import { ICheck } from 'ui/component/Icons.tsx';
import { Modal } from 'ui/component/Modal.tsx';
import { Spinner } from 'ui/component/Spinner.tsx';
import { Checkbox } from 'ui/control/Checkbox.tsx';
import { Input } from 'ui/control/Input.tsx';
import { SelectMulti } from 'ui/control/SelectMulti.tsx';
import { useMe } from '#admin/hook/useMe.tsx';

type FormValues = {
  id?: string;
  logistics_ex_id: string;
  name: string;
  email: string;
  phone?: string;
  vat_number: string;
  address: string;
  role: ROLE | '';
  owner_id: string;
  send_credentials: boolean;
};

export type GroupFormProps = {
  userId?: string;
  onSave?: (group: User) => void;
  onClose?: () => void;
  isOpen: boolean;
};

export const UserForm = ({ userId, onSave, onClose, isOpen }: GroupFormProps) => {
  const me = useMe();
  const isEdit = !!userId;
  const thisUserSWR = useSWR<User>(isEdit ? [`/user/${userId}`] : null, GET);
  const usersSWR = useSWR<User[]>(['/user/list', { is_group: true, sort: [['name', 'asc']] }], POST);
  const rolesSWR = useSWR<Role[]>(['/role/list', { sort: [['name', 'asc']] }], POST);
  const allUsers = usersSWR.data;
  const thisUser = thisUserSWR.data;
  const allRoles = rolesSWR.data;

  const createMutation = useSWRMutation('/user', MPOST);
  const updateMutation = useSWRMutation(`/user/${userId}`, MPUT);
  const existsMutation = useSWRMutation('/user/exists', MPOST);

  const isLoading = !!(createMutation.isMutating || updateMutation.isMutating || usersSWR.isValidating || (userId && thisUserSWR.isValidating) || rolesSWR.isValidating);
  const isError = createMutation.error || updateMutation.error || usersSWR.error || (userId && thisUserSWR.error);

  const availableParents = React.useMemo(() => {
    if (!allUsers) return {};
    const options: Record<string, string> = {};
    for (const group of allUsers.filter((group) => group.id !== userId)) {
      options[group.id] = `${group.name} (role=${group.role})`;
    }
    return options;
  }, [allUsers, userId]);

  const availableRoles = React.useMemo(() => {
    if (!allRoles) return [];
    const roles = allRoles.map((role) => role.name);
    return roles;
  }, [allRoles]);

  const formMethods = useForm<FormValues>({
    mode: 'onTouched',
    criteriaMode: 'all',
    reValidateMode: 'onChange',
    shouldUnregister: true,
    defaultValues: {
      logistics_ex_id: thisUser?.logistics_ex_id || '',
      name: thisUser?.name,
      email: thisUser?.email,
      phone: thisUser?.phone || '',
      vat_number: thisUser?.vat_number || '',
      address: thisUser?.address || '',
      role: thisUser?.role || 'MEMBER',
      owner_id: '',
      send_credentials: false,
    }
  });

  const { register, handleSubmit, formState, reset } = formMethods;

  React.useEffect(() => {
    if (!allUsers) return;
    const owner = allUsers.find((company) => company.id === thisUser?.owner_id);
    if (!owner) return;
    reset({
      logistics_ex_id: thisUser?.logistics_ex_id || '',
      name: thisUser?.name,
      email: thisUser?.email,
      phone: thisUser?.phone || '',
      vat_number: thisUser?.vat_number || '',
      address: thisUser?.address || '',
      role: thisUser?.role || 'MEMBER',
      owner_id: owner.id,
      send_credentials: false,
    });
  }, [thisUser, allUsers, reset]);

  const onSubmit = async (values: FormValues) => {
    if (!me) return;
    const selectedParent = allUsers?.find((company) => company.id === values.owner_id);
    if (!selectedParent) return;

    const payload: Record<string, any> = {
      id: thisUser?.id || undefined,
      logistics_ex_id: values.logistics_ex_id || thisUser?.logistics_ex_id,
      name: values.name,
      email: isEdit ? undefined : values.email,
      phone: values.phone,
      vat_number: values.vat_number,
      address: values.address,
      role: values.role,
      owner_id: values.owner_id,
      company_name: selectedParent.name || thisUser?.company_name,
      send_credentials: values.send_credentials,
      is_group: false,
    };

    let response: User;
    if (isEdit) response = await updateMutation.trigger(payload);
    else response = await createMutation.trigger(payload);

    if (onSave) void onSave(response);
  };

  const isUniqueLogisticsExId = React.useMemo(
    () =>
      // @ts-ignore
      debounce(async (value: string) => {
        if (!value || (isEdit && value === thisUser?.logistics_ex_id)) return true;
        const groupExists = await existsMutation.trigger({ logistics_ex_id: value });
        return !groupExists;
      }, 200),
    [existsMutation, isEdit, thisUser?.logistics_ex_id],
  );

  const isUniqueEmail = React.useMemo(
    () =>
      // @ts-ignore
      debounce(async (value: string) => {
        if (!value) return true;
        // This is to avoid sending unnecessary requests when the user is typing the email.
        if (!isValidEmail(value)) return false;
        if (isEdit && value === thisUser?.email) return true;

        // This form is used both for user creation and updates (eg: profile).
        // If the user is changing its data, the user is already saved and the email is already in db.
        // In that case, we check that the email is not in use by another user ONLY IF the user is changing it.
        if (isEdit && value === thisUser?.email) return true;
        const groupExists = await existsMutation.trigger({ email: value });
        return !groupExists;
      }, 200),
    [isEdit, thisUser?.email, existsMutation],
  );

  return (
    <Modal title={<h3>{isEdit ? `Edit user ${thisUser?.name}` : 'New user'}</h3>} isOpen={isOpen} onClose={onClose}>
      {(!me || isLoading) && <Spinner centered={true} overlay={true} blur={true} />}

      <FormProvider {...formMethods}>
        <form className="w-[500px]" autoCorrect={'off'} autoComplete={'off'} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
          {userId && <input type={'hidden'} {...register('id')} />}
          <div className="flex h-[500px] flex-col gap-6 overflow-y-scroll p-6">
            <Input
              className="w-full"
              name={'name'}
              label={'Name'}
              placeholder="e.g. John Doe"
              registerOptions={{ required: true }}
              autoFocus={false} //
            >
              {formState.errors.name?.type === 'required' ? 'Required' : null}
            </Input>

            <Input
              name={'email'}
              label={'Email'}
              placeholder="e.g. john.doe@gmail.com"
              disabled={isEdit && thisUser?.sso_only}
              registerOptions={{ required: true, validate: { isEmail: isValidEmail, isUnique: isUniqueEmail } }}
            >
              {formState.errors.email?.type === 'required' ? 'Required' : null}
              {formState.errors.email?.type === 'isUnique' ? 'Email already in use' : null}
              {formState.errors.email?.type === 'isEmail' ? 'Invalid email format' : null}
            </Input>

            <SelectMulti
              className="w-full"
              name={'owner_id'}
              label={'Parent group'}
              options={availableParents}
              selectable={'one'}
              closeOnChangedValue={true}
              required={true}
              hasClearAll={true}
            />

            <SelectMulti
              className="hidden w-full"
              name={'role'}
              label={'Role'}
              options={availableRoles}
              selectable={'one'}
              closeOnChangedValue={true}
              required={true}
              hasClearAll={true}
            />

            <Input
              className="w-full"
              name={'logistics_ex_id'}
              label={'Logistics Provider ID'}
              placeholder="e.g. 123456"
              registerOptions={{ validate: { isUnique: isUniqueLogisticsExId } }}
              autoFocus={false}
            >
              {formState.errors.logistics_ex_id?.type === 'required' ? 'Required' : null}
              {formState.errors.logistics_ex_id?.type === 'isValidLogisticsExId' ? 'Invalid Logistics ID' : null}
              {formState.errors.logistics_ex_id?.type === 'isUnique' ? 'Logistics ID already in use' : null}
            </Input>

            <Input name={'phone'} label={'Phone number'} placeholder="e.g. +1234567890" className="w-full"/>
            <Input name={'vat_number'} label={'VAT Number'} placeholder="e.g. 1234567890" className="w-full"/>
            <Input name={'address'} label={'Address'} placeholder="e.g. 123 Main St"/>

            {!isEdit && <Checkbox name={'send_credentials'} label={'Send credentials'} />}
          </div>

          <div className="mt-8 flex items-center gap-4">
            <Button type="button" className="blue-outlined col-span-2 justify-self-end" onClick={onClose}>
              BACK
            </Button>
            <Button
              className="blue col-span-2 justify-self-end"
              loading={isLoading}
              disabled={!formState.isValid || !formState.isDirty}
              RightIcon={!isLoading && formState.isSubmitSuccessful ? ICheck : undefined}
              type="submit"
            >
              SAVE
            </Button>
          </div>

          {!!isError && <div className="mt-2 text-red">There was an error while processing your request. Please try again later.</div>}
        </form>
      </FormProvider>
    </Modal>
  );
};
