import type { ColumnDef, SortingState } from '@tanstack/react-table';
import cx from 'clsx';
import { dateToYMDHMS } from 'common/date.ts';
import { POST, emptyToUndefined, fromQuerystring, toQuerystring, truncate } from 'common/helpers.ts';
import { isEqual } from 'es-toolkit';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import type { Activity } from 'types/Activity.ts';
import { Button } from 'ui/component/Button.tsx';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import { ISearch } from 'ui/component/Icons.tsx';
import { Input } from 'ui/control/Input.tsx';
import { Table, getSWRKeyForPage } from '#admin/component/Table.tsx';

const getColumns = () => {
  const columns: ColumnDef<Activity>[] = [
    {
      header: 'ID',
      accessorKey: 'id',
      minSize: 100,
      cell: (info) => {
        const value = info.getValue<string>();
        return (
          <CopyableText copyText={value} className="font-mono">
            {truncate(value, 8, '')}
          </CopyableText>
        );
      },
    },
    {
      header: 'Generator',
      accessorFn: (row) => row.generator_name,
      enableSorting: false,
      minSize: 180,
    },
    {
      header: 'Action',
      accessorKey: 'action',
      minSize: 100,
      cell: (info) => {
        const value = info.getValue<string>();
        return <span className="font-bold">{value}</span>;
      },
    },
    {
      header: 'Resource Type',
      accessorKey: 'resource_type',
      minSize: 180,
    },
    {
      header: 'Resource ID',
      accessorKey: 'resource_id',
      minSize: 100,
      cell: (info) => {
        const value = info.getValue<string>();
        if (!value) return '-';

        return (
          <CopyableText copyText={value} className="font-mono">
            {truncate(value, 8, '')}
          </CopyableText>
        );
      },
    },
    {
      header: 'Date',
      accessorKey: 'created_at',
      minSize: 180,
      cell: (info) => {
        const value = info.getValue<string>();
        return dateToYMDHMS(value);
      },
    },
  ];

  return columns;
};

type FormValues = {
  search: string;
};

export const ActivityList = () => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = React.useState<boolean>(false);
  const [sorters, setSorters] = React.useState<SortingState>([{ id: 'created_at', desc: true }]);

  const defaultValues: FormValues = { search: '' };
  const defaultValuesWithSearchValues = { ...defaultValues, ...fromQuerystring(searchParams) };
  const [values, setValues] = React.useState<typeof defaultValues>(defaultValuesWithSearchValues);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: defaultValuesWithSearchValues,
  });
  const { handleSubmit, formState, reset, getValues } = formContext;

  const { data: rowsCount } = useSWR(formState.isValid ? ['/activity/count', values, emptyToUndefined] : null, POST);
  const swr = useSWRInfinite(getSWRKeyForPage('/activity/list', 25, rowsCount, values, sorters), POST);

  const toggleFilters = () => setShowFilters(!showFilters);

  const resetFilters = () => {
    reset(defaultValues);
    triggerSubmit();
  };

  // wait next tick or URL will not be updated
  const triggerSubmit = () => window.setTimeout(() => formRef.current?.requestSubmit(), 50);

  const onSubmit = (values: FormValues) => {
    setSearchParams(toQuerystring(values), { replace: true });
    setValues(values);
  };

  const columns = React.useMemo(() => getColumns(), []);

  // we can't rely only on formState.isDirty because it's true when defaultValues provided to useForm are changed, but we may have filters set by default provided in the URL
  const clearFiltersEnabled = formState.isDirty || !isEqual(defaultValues, getValues());

  return (
    <main className="view-container">
      <section className="flex flex-row place-content-between items-center">
        <h4>Activity</h4>
      </section>

      <section className="mt-4 rounded-2xl bg-white px-8 py-2">
        <FormProvider {...formContext}>
          <form ref={formRef} className="my-8 flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            {/* search and button to expand filters section */}
            <div className="flex place-content-between place-items-center">
              <Input
                className="w-[500px]"
                name={'search'}
                placeholder={'Search by ID, Type, Description, Action, Generator or Resource'}
                required={true}
                autoFocus={true}
                RightIcon={ISearch}
                autoComplete={'off'}
              />
            </div>
            {/* expanded filters section */}
            <div className={cx('flex flex-col gap-6 rounded-xl bg-blue-sky p-4', !showFilters && 'hidden')}>
              <div className="flex flex-row gap-6" />
              <div className="flex flex-row gap-4">
                <Button className={'blue min-w-max max-w-max'} onClick={toggleFilters}>
                  Close
                </Button>
                <Button className={'blue ml-auto min-w-max max-w-max'} onClick={resetFilters} disabled={!clearFiltersEnabled}>
                  Clear filters
                </Button>
                <Button className="blue-outlined min-w-max max-w-max" type="submit" disabled={showFilters && !formState.isDirty}>
                  Apply
                </Button>
              </div>
            </div>
          </form>
        </FormProvider>

        <Table
          className={cx('max-h-[calc(100vh-375px)]')}
          columns={columns}
          swr={swr}
          pagination={true}
          rowsCount={rowsCount}
          defaultSorting={sorters}
          onColumnSort={setSorters}
          estimateSize={50}
          overscan={50}
        />
      </section>
    </main>
  );
};
