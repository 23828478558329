import type { EnvControllerGetResponse } from 'api/env/Env.schema.ts';
import { AxiosError } from 'axios';
import BlockchainError from 'blockchain/BlockchainError.ts';
import { VAULT_URL_PATH } from 'common/constants.ts';
import { BaseError, errorCodes, errorMessages } from 'common/errors/index.ts';
import { MPOST, addHexPrefix, computeHash, encryptDocument, isNetworkError, sanitizeFilename } from 'common/helpers.ts';
import useMe from 'driver/hook/useMe.tsx';
import React from 'react';
import useSWRMutation from 'swr/mutation';
import useBlockchain from './useBlockchain.ts';

export type FileMetadata = Record<string, string>;

export type Upload = {
  type: 'generic' | 'poc' | 'pod';
  signerName: string;
  file: File;
  metadata?: FileMetadata;
};

export type UploadedFile = Upload & {
  url: string;
  presignedUrl: string;
};

export type UseUploadFileOptions = {
  onSuccess?: (uploadedFile: UploadedFile) => void | Promise<void>;
  onError?: (error: unknown, upload: Upload) => void | Promise<void>;
};

export default function useUploadFile(env?: EnvControllerGetResponse | null, options: UseUploadFileOptions = {}) {
  const { me } = useMe();
  const { vault } = useBlockchain(env);
  const [isUploading, setIsUploading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [error, setError] = React.useState<Error | null>(null);
  const [url, setUrl] = React.useState<string>('');
  const networkError = React.useMemo(() => isNetworkError(error), [error]);

  const { trigger: triggerPresignedUrl } = useSWRMutation('/storage/presigned-url', MPOST);
  const { trigger: triggerCreateDocument } = useSWRMutation('/document', MPOST);

  const upload = React.useCallback(
    async (upload: Upload) => {
      const { type, signerName, file, metadata = {} } = upload;
      const { onSuccess, onError } = options;
      let url: string | undefined = undefined;
      let presignedURL: string | undefined = undefined;
      let caughtError: Error | undefined = undefined;

      if (!me || !vault || !env || !file.size) return { url, error: caughtError };

      setErrorMessage('');
      setIsUploading(true);
      setUrl('');

      try {
        const filename = sanitizeFilename(file.name);
        const docArrayBuffer = await file.arrayBuffer();

        //////////////////////////////
        // ENCRYPTION + PRESIGNED URL
        //////////////////////////////

        // Calculate SHA256 of the file:
        const hashString = await computeHash(docArrayBuffer);

        // Generate random key and encrypt file:
        const { encryptionKey, encryptedDocument } = await encryptDocument(docArrayBuffer);

        // Get presigned URL:
        try {
          presignedURL = await triggerPresignedUrl({ filename: hashString });
          if (!presignedURL) throw new BaseError(errorCodes.STORAGE_FAILED_TO_GET_PRESIGNED_URL, errorMessages.STORAGE_FAILED_TO_GET_PRESIGNED_URL);
          url = `${new URL(presignedURL).origin}/${VAULT_URL_PATH}/${hashString}`;
        } catch (e) {
          if (e instanceof AxiosError) {
            setError(e);
            e.message = e.response?.data?.message || 'Failed to get presigned URL';
          }
          throw e;
        }

        //////////////////////////////
        // VAULT UPLOAD
        //////////////////////////////
        try {
          // Send encrypted file to storage (not using axios because of the interceptors)
          await fetch(presignedURL, {
            headers: { Accept: 'application/xml', 'content-type': 'application/octet-stream', 'x-ms-blob-type': 'BlockBlob' },
            body: encryptedDocument,
            mode: 'cors',
            method: 'PUT',
          });
        } catch (e) {
          if (e instanceof Error) {
            console.error('Failed to upload file to storage:', e.message);
            throw new Error('Failed to upload file to storage. Please try again later.');
          }
          throw e;
        }

        //////////////////////////////
        // SMART CONTRACT
        //////////////////////////////
        try {
          const hash = addHexPrefix(hashString);

          const document = {
            encryptionAlg: 'AES-CBC',
            encryptionKey,
            hash,
            hashAlg: 'SHA-256',
            url,
            metadata: [
              { key: 'filename', value: filename },
              { key: 'type', value: type },
              { key: 'signer_name', value: signerName },
              ...Object.entries(metadata).map(([key, value]) => ({ key, value })),
            ],
            ownerId: me.id,
          };

          // we need to create the document and at the same time give permission to both the admin
          // (so APIGW can align permissions as needed) and the driver (so he can see the document right after upload)
          await vault.createDocumentAndPermissions(document, [env.SUPERADMIN_ID, me.id]);

          //////////////////////////////
          // ATLAS
          //////////////////////////////
          await triggerCreateDocument({ url: presignedURL, filename, name: filename, type });
        } catch (e) {
          console.error(e);
          if (e instanceof BlockchainError && e.errorName !== 'VaultDocumentAlreadyExists') {
            throw new Error('Failed to save document to blockchain. Please try again.');
          }
          throw e;
        }

        await onSuccess?.({ ...upload, url, presignedUrl: presignedURL });
        setUrl(url);
        setIsUploaded(true);
      } catch (error) {
        console.error(error);
        if (error instanceof AxiosError) {
          setErrorMessage(error.response?.data?.message || error.message);
          setError(error);
          caughtError = error;
        } else if (error instanceof Error) {
          setErrorMessage(error.message);
          setError(error);
          caughtError = error;
        }
        await onError?.(error, upload);
      }

      setIsUploading(false);
      return { url, error: caughtError };
    },
    [me, triggerPresignedUrl, triggerCreateDocument, vault, options, env],
  );

  return { upload, isUploading, errorMessage, isUploaded, error, url, networkError };
}
