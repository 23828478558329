import * as Sentry from '@sentry/react';
import type { AuthMeResponse } from 'api/auth/Auth.schemas.ts';
import { GET } from 'common/helpers.ts';
import React from 'react';
import { useSelector } from 'react-redux';
import useLogout from '#driver/hook/useLogout.ts';
import useSwrOffline from '#driver/hook/useSwrOffline.ts';
import { StorageKeys, getObject, putObject } from '#driver/offlineStorage/storage.ts';
import type { RootState } from '#driver/store.ts';

export default function useMe() {
  const token = useSelector((state: RootState) => state.app.token);
  const logout = useLogout();

  const onUpdateUserInOfflineStorage = React.useCallback((user: AuthMeResponse) => {
    void putObject(StorageKeys.Users, user, 'me');
  }, []);

  const getMeFromOfflineStorage = React.useCallback(() => {
    return getObject<AuthMeResponse>(StorageKeys.Users, 'me');
  }, []);

  const {
    data: me,
    refresh,
    error,
    networkError,
    isLoading,
  } = useSwrOffline<AuthMeResponse>(token ? ['/auth/me'] : null, GET, getMeFromOfflineStorage, onUpdateUserInOfflineStorage);

  React.useEffect(() => Sentry.setUser(me || null), [me]);

  React.useEffect(() => {
    if (error) void logout();
  }, [error, logout]);

  return { me, refresh, error, networkError, isLoading };
}
