import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button } from 'ui/component/Button.tsx';
import useVerifyDocuments, { type VerificationResult } from '#admin/hook/useVerifyDocument.ts';
import { FileUpload } from './FileUpload.tsx';

type FormValues = {
  file: File | null;
};

type Props = {
  title: string;
  onVerify: (result: VerificationResult) => void;
};

export const VerifyDocumentForm: React.FC<Props> = ({ title, onVerify }) => {
  const formMethods = useForm<FormValues>({
    defaultValues: {
      file: null,
    },
  });
  const [files, setFiles] = React.useState<File[]>([]);
  const { control, formState, handleSubmit } = formMethods;
  const { isValid } = formState;
  const { isVerifying, results } = useVerifyDocuments(files);

  const onSubmit = ({ file }: FormValues) => {
    if (!file) return;
    setFiles([file]);
  };

  React.useEffect(() => {
    if (results.length === 0 || isVerifying) {
      return;
    }
    const [result] = results;
    onVerify(result);
  }, [results, isVerifying, onVerify]);

  return (
    <FormProvider {...formMethods}>
      <form className="flex w-full max-w-[50rem] flex-col items-center justify-center gap-12 p-8" onSubmit={handleSubmit(onSubmit)}>
        <h5 className="text-grey-body">{title}</h5>
        <Controller
          disabled={isVerifying}
          control={control}
          name="file"
          rules={{ required: 'File is required' }}
          render={({ field }) => <FileUpload onChange={field.onChange} value={field.value} />}
        />
        <div className="flex w-full flex-row justify-end">
          <Button className="blue !w-fit" type="submit" disabled={!isValid} loading={isVerifying}>
            Verify Document
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
