import React from 'react';
import { CopyableText } from 'ui/component/CopyableText.tsx';
import type { VerificationResult } from '#admin/hook/useVerifyDocument.ts';

type Props = {
  document: VerificationResult;
};

function getStatusText(status: string) {
  switch (status) {
    case 'verified':
      return 'Verified';
    case 'not-verified':
      return 'Not Verified';
    case 'error':
      return 'Error';
    default:
      return 'Unknown';
  }
}

function createCopyText(document) {
  const { filename, status, timestamp, hash, vaultDocumentInfo, organizationName, creationTimestamp } = document;
  const { encryptionKey, originalUrl, type } = vaultDocumentInfo || {};
  let copyText = '';
  copyText += `Document: ${filename}\n`;
  if (organizationName) copyText += `Organization: ${organizationName}\n`;
  copyText += `Timestamp: ${timestamp}\n`;
  creationTimestamp && (copyText += `Creation Timestamp: ${new Date(Number(document.creationTimestamp) * 1000).toLocaleString()}\n`);
  copyText += `SHA-256: ${hash}\n`;
  type && (copyText += `Type: ${type}\n`);
  originalUrl && (copyText += `Original URL: ${originalUrl}\n`);
  encryptionKey && (copyText += `Encryption Key: ${encryptionKey}\n`);
  status && (copyText += `Status: ${getStatusText(status)}\n`);
  return copyText;
}

export const VerificationCard: React.FC<Props> = ({ document }) => {
  const { filename, status, timestamp, hash, organizationName, creationTimestamp } = document;

  const creationDate = new Date(Number(creationTimestamp) * 1000).toLocaleString();
  const copyText = React.useMemo(() => createCopyText(document), [document]);

  return (
    <div className="relative w-full">
      <CopyableText copyText={copyText} tooltipText="Copy to clipboard" className="absolute top-4 right-4" />
      <section className="flex flex-col gap-4 overflow-scroll rounded-2xl bg-blue-light p-8">
        {status === 'verified' && (
          <div className="flex flex-row gap-1">
            <span className="body1 min-w-36 shrink grow-0 text-grey-label">Organization</span>
            <span className="body2 shrink-0 grow text-grey-body">{organizationName}</span>
          </div>
        )}
        <div className="flex flex-row gap-1">
          <span className="body1 min-w-36 shrink grow-0 text-grey-label">Document</span>
          <span className="body2 shrink-0 grow text-grey-body">{filename}</span>
        </div>
        <div className="flex flex-row gap-1">
          <span className="body1 min-w-36 shrink grow-0 text-grey-label">Verification date</span>
          <span className="body2 shrink-0 grow text-grey-body">{timestamp}</span>
        </div>
        {creationTimestamp !== undefined && (
          <div className="flex flex-row gap-1">
            <span className="body1 min-w-36 shrink grow-0 text-grey-label">Creation date</span>
            <span className="body2 shrink-0 grow text-grey-body">{creationDate}</span>
          </div>
        )}
        <div className="flex flex-row gap-1">
          <span className="body1 min-w-36 shrink grow-0 text-grey-label">SHA-256</span>
          <span className="body2 shrink-0 grow text-grey-body">{hash}</span>
        </div>
      </section>
    </div>
  );
};
