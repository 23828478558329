import { Link } from 'react-router-dom';
import { Button } from 'ui/component/Button.tsx';

export const NotFound = () => {
  return (
    <main className="flex items-center justify-center">
      <div className="-z-30 absolute inset-0 flex flex-col items-center justify-center gap-16 bg-blue-light">
        <img src="/404.svg" alt="Content not found." />
        <Link to="/">
          <Button className="blue">GO TO THE HOMEPAGE</Button>
        </Link>
      </div>
    </main>
  );
};
