import { MPOST, POST } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { Permission, PermissionRowInsert } from 'types/Permission.ts';
import type { User } from 'types/User.ts';
import { Button } from 'ui/component/Button.tsx';
import { ISwap } from 'ui/component/Icons.tsx';
import { Input } from 'ui/control/Input.tsx';
import { Select } from 'ui/control/Select.tsx';

type AddPermissionFormProps = {
  user?: User;
  onAdd: (values: Permission) => void;
};

export const AddPermissionForm = ({ user, onAdd }: AddPermissionFormProps) => {
  const [resourceMode, setResourceMode] = React.useState<'select' | 'text'>('select');

  const allUsersSWR = useSWR<User[]>(['/user/list', { sort: [['name', 'asc']] }], POST);
  const allOperationsSWR = useSWR<string[]>(['/permission/list-operations', {}], POST);

  const userIdUserMap = React.useMemo(() => {
    if (!allUsersSWR.data) return {};
    return allUsersSWR.data.reduce(
      (acc, user) => {
        acc[user.id] = user;
        return acc;
      },
      {} as Record<string, User>,
    );
  }, [allUsersSWR.data]);

  const createPermissionSWR = useSWRMutation('/permission', MPOST);

  const availableSubjects = React.useMemo(() => {
    if (!allUsersSWR.data) return {};
    const mappedUsers = allUsersSWR.data.reduce(
      (acc, user) => {
        acc[user.id] = user.name + (user.is_group ? ' (Group)' : '');
        return acc;
      },
      {} as Record<string, string>,
    );
    return mappedUsers;
  }, [allUsersSWR.data]);

  const availableResources = React.useMemo(() => {
    if (!allUsersSWR.data) return {};
    const mappedUsers = allUsersSWR.data.reduce(
      (acc, user) => {
        acc[user.id] = user.name + (user.is_group ? ' (Group)' : '');
        return acc;
      },
      {} as Record<string, string>,
    );
    return mappedUsers;
  }, [allUsersSWR.data]);

  const availableOperations = React.useMemo(() => {
    if (!allOperationsSWR.data) return {};
    const mappedOperations = allOperationsSWR.data
      .filter((operation) => operation !== '*')
      .reduce(
        (acc, operation) => {
          acc[operation] = operation;
          return acc;
        },
        {} as Record<string, string>,
      );
    return {
      '*': 'All Operations',
      ...mappedOperations,
    };
  }, [allOperationsSWR.data]);

  const formRef = React.useRef<HTMLFormElement>(null);
  const formContext = useForm<PermissionRowInsert>({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues: {
      subject: user?.id,
      resource: user?.id,
    },
  });
  const { handleSubmit, reset } = formContext;

  const onSubmit = async (values: PermissionRowInsert) => {
    if (!confirm('Are you sure you want to add the permission?')) return;
    const resource_is_owner = userIdUserMap[values.resource]?.is_group ?? false;
    const permission = await createPermissionSWR.trigger({
      ...values,
      subject_is_group: userIdUserMap[values.subject]?.is_group ?? false, // Intentional '?' here
      resource_is_owner,
    });
    if (resource_is_owner && ['UserGet', '*'].includes(values.operation)) {
      await createPermissionSWR.trigger({
        ...values,
        operation: 'UserGet',
        subject_is_group: userIdUserMap[values.subject]?.is_group ?? false,
        resource_is_owner: false, // The user can read the group itself
      });
    }
    void onAdd(permission);
    reset();
  };

  const SwitchResourceModeButton = ({ text }: { text: string }) => (
    <div className="flex items-center gap-2" onClick={() => setResourceMode(resourceMode === 'select' ? 'text' : 'select')}>
      <span>
        {text}
        {resourceMode === 'text' ? ' ID' : ''}
      </span>
      <ISwap size={18} className="cursor-pointer rounded-md text-blue-600" />
    </div>
  );

  return (
    <FormProvider {...formContext}>
      <form id="add-permission-form" ref={formRef} className="flex flex-col gap-8" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className="rounded-2xl bg-blue-sky p-6">
          <div className="flex items-end gap-4">
            <Select
              className="w-[240px]"
              name={'subject'}
              label={'Subject'}
              placeholder={'Select Subject'}
              required={true}
              autoComplete={'off'}
              options={availableSubjects}
              registerOptions={{ required: true }}
            />
            <Select
              className="w-[240px]"
              name={'operation'}
              label="Operation"
              placeholder={'Select Operation'}
              required={true}
              autoComplete={'off'}
              options={availableOperations}
              registerOptions={{ required: true }}
            />
            {resourceMode === 'select' ? (
              <Select
                className="w-[240px]"
                name={'resource'}
                label={<SwitchResourceModeButton text="Resource" />}
                placeholder={'Select Resource'}
                required={true}
                autoComplete={'off'}
                options={availableResources}
                registerOptions={{ required: true }}
              />
            ) : (
              <Input
                className="h-[52px] w-[240px]"
                name={'resource'}
                label={<SwitchResourceModeButton text="Resource" />}
                placeholder={'Enter Resource'}
                required={true}
                autoComplete={'off'}
                registerOptions={{ required: true }}
              />
            )}
            <Button loading={createPermissionSWR.isMutating} className="blue h-[37px] w-[150px] max-w-[150px]" type="submit">
              ADD
            </Button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
