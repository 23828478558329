import cx from 'clsx';
import { MPUT, POST, isGranted } from 'common/helpers.ts';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import type { User } from 'types/User.ts';
import { Button } from 'ui/component/Button.tsx';
import { Select } from 'ui/control/Select.tsx';
import { Table } from '#admin/component/Table.tsx';
import { useMe } from '#admin/hook/useMe.tsx';
import { getColumns as getUserColumns } from '#admin/views/UserList.tsx';

type UserListFormValues = { id: string };

type Props = {
  className?: string;
  title?: string;
  user: User;
};

export const UserList = ({ className, title, user: group }: Props) => {
  const me = useMe();
  const defaultValues: UserListFormValues = { id: '' };

  const allUsersSWR = useSWR<User[]>(['/user/list', { sort: [['name', 'asc']] }], POST);
  const availableUsers = React.useMemo(() => {
    if (!allUsersSWR.data) return {};
    const mappedUsers = allUsersSWR.data.reduce(
      (acc, user) => {
        acc[user.id] = user.name + (user.is_group ? ' (Group)' : '');
        return acc;
      },
      {} as Record<string, string>,
    );
    return {
      ...mappedUsers,
    };
  }, [allUsersSWR.data]);

  const groupUsers = React.useMemo(() => {
    if (!allUsersSWR.data) return [];
    return allUsersSWR.data.filter((user) => user.owner_id === group.id);
  }, [allUsersSWR.data, group.id]);

  const formContext = useForm({
    mode: 'onTouched',
    criteriaMode: 'all',
    shouldUnregister: true,
    shouldUseNativeValidation: false,
    shouldFocusError: true,
    defaultValues,
  });
  const { handleSubmit, watch } = formContext;
  const id = watch('id');

  const updateUserSWR = useSWRMutation(`/user/${id}`, MPUT);

  const onSubmit = async ({ id }: UserListFormValues) => {
    if (!confirm('Are you sure you want to add the user to the group?')) return;
    await updateUserSWR.trigger({ id, owner_id: group.id });
    await allUsersSWR.mutate();
  };

  const columns = React.useMemo(() => getUserColumns(() => void allUsersSWR.mutate()).filter((column) => !['created_at'].includes(column.id || '')), [allUsersSWR]);

  if (!me) return null;

  return (
    <div className={cx(className, 'flex flex-col gap-4 rounded-2xl bg-white')}>
      {title && <h4>{title}</h4>}
      <section className="rounded-2xl bg-white">
        <FormProvider {...formContext}>
          <form className="mb-8 flex flex-row items-end gap-8 rounded-2xl bg-blue-sky p-6" noValidate={true} onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Select
              className="w-full"
              disabled={!isGranted(me, 'UserChown', group.id)}
              name={'id'}
              label={'Users'}
              placeholder={'Select User'}
              required={true}
              autoComplete={'off'}
              options={availableUsers}
              registerOptions={{ required: true }}
            />
            <Button disabled={!isGranted(me, 'UserChown', group.id)} loading={updateUserSWR.isMutating} type="submit" className="blue h-[37px] w-[150px] max-w-[150px]">
              Add
            </Button>
          </form>
        </FormProvider>
        <Table className={cx('max-h-[calc(100vh-445px)]')} columns={columns} items={groupUsers} estimateSize={50} overscan={50} />
      </section>
    </div>
  );
};
