import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Spinner } from 'ui/component/Spinner.tsx';
import { AuthGate } from '#admin/component/AuthGate.tsx';
import { Layout } from '#admin/component/Layout.tsx';
import { PasswordRequest } from '#admin/component/PasswordRequest.tsx';
import { PasswordReset } from '#admin/component/PasswordReset.tsx';
import { APIKeyList } from '#admin/views/APIKeyList.tsx';
import { ActivityList } from '#admin/views/ActivityList.tsx';
import { AlertList } from '#admin/views/AlertList.tsx';
import { Auth } from '#admin/views/Auth.tsx';
import { GroupDiagram } from '#admin/views/GroupDiagram.tsx';
import { GroupList } from '#admin/views/GroupList.tsx';
import { Home } from '#admin/views/Home.tsx';
import { Maintenance } from '#admin/views/Maintenance.tsx';
import { NotFound } from '#admin/views/NotFound.tsx';
import { Operator } from '#admin/views/Operator.tsx';
import { Ops } from '#admin/views/Ops.tsx';
import { PermissionList } from '#admin/views/PermissionList.tsx';
import { Role } from '#admin/views/Role.tsx';
import { RoleList } from '#admin/views/RoleList.tsx';
import { ShipmentDetail } from '#admin/views/Shipment.tsx';
import { ShipmentList } from '#admin/views/ShipmentList.tsx';
import { Tec4CloudConfig } from '#admin/views/Tec4CloudConfig.tsx';
import { TrackerDetail } from '#admin/views/Tracker.tsx';
import { TrackerList } from '#admin/views/TrackerList.tsx';
import { UserDetail } from '#admin/views/User.tsx';
import { UserList } from '#admin/views/UserList.tsx';
import { VerifyDocument } from '#admin/views/VerifyDocument.tsx';

export const Router = () => {
  return (
    <BrowserRouter basename={'/'}>
      <ErrorBoundary fallback={<Ops />}>
        <React.Suspense fallback={<Spinner centered={true} />}>
          <Routes>
            <Route path={'/'} element={<Layout />}>
              <Route path={'/'} element={<AuthGate ifAuthed={true} element={<Navigate to={'/home'} />} elseTo={'/auth'} />} />
              <Route path={'/auth'} element={<AuthGate ifAuthed={false} element={<Auth onSuccess={'/'} />} elseTo={'/'} />} />
              <Route path={'/password-request'} element={<AuthGate ifAuthed={false} element={<PasswordRequest />} elseTo={'/'} />} />
              <Route path={'/password-reset'} element={<AuthGate ifAuthed={false} element={<PasswordReset />} elseTo={'/'} />} />
              <Route path={'/home'} element={<AuthGate ifAuthed={true} element={<Home />} elseTo={'/auth'} />} />
              <Route path={'/operator'} element={<AuthGate ifAuthed={true} element={<Operator />} elseTo={'/auth'} />} />
              <Route path={'/users'} element={<AuthGate ifAuthed={true} element={<UserList />} elseTo={'/auth'} />} />
              <Route path={'/user/:id?'} element={<AuthGate ifAuthed={true} element={<UserDetail />} elseTo={'/auth'} />} />
              <Route path={'/shipment/:id'} element={<AuthGate ifAuthed={true} element={<ShipmentDetail />} elseTo={'/auth'} />} />
              <Route path={'/shipments'} element={<AuthGate ifAuthed={true} element={<ShipmentList />} elseTo={'/auth'} />} />
              <Route path={'/tracker/:id'} element={<AuthGate ifAuthed={true} element={<TrackerDetail />} elseTo={'/auth'} />} />
              <Route path={'/trackers'} element={<AuthGate ifAuthed={true} element={<TrackerList />} elseTo={'/auth'} />} />
              <Route path={'/alerts'} element={<AuthGate ifAuthed={true} element={<AlertList />} elseTo={'/auth'} />} />
              <Route path={'/activities'} element={<AuthGate ifAuthed={true} element={<ActivityList />} elseTo={'/auth'} />} />
              <Route path={'/api-keys'} element={<AuthGate ifAuthed={true} element={<APIKeyList />} elseTo={'/auth'} />} />
              <Route path={'/tec4cloud-config'} element={<AuthGate ifAuthed={true} element={<Tec4CloudConfig />} elseTo={'/auth'} />} />
              <Route path={'/permissions'} element={<AuthGate ifAuthed={true} element={<PermissionList />} elseTo={'/auth'} />} />
              <Route path={'/roles'} element={<AuthGate ifAuthed={true} element={<RoleList />} elseTo={'/auth'} />} />
              <Route path={'/role/:id'} element={<AuthGate ifAuthed={true} element={<Role />} elseTo={'/auth'} />} />
              <Route path={'/groups'} element={<AuthGate ifAuthed={true} element={<GroupList />} elseTo={'/auth'} />} />
              <Route path={'/groups-hierarchy'} element={<AuthGate ifAuthed={true} element={<GroupDiagram />} elseTo={'/auth'} />} />
              <Route path={'/verify'} element={<VerifyDocument />} />
              <Route path={'/maintenance'} element={<Maintenance />} />
              <Route path={'*'} element={<NotFound />} />
            </Route>
          </Routes>
        </React.Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  );
};
