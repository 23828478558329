import React from 'react';
import { Link } from 'react-router-dom';
import { DocumentNotVerified } from '#admin/component/DocumentNotVerified.tsx';
import { DocumentVerified } from '#admin/component/DocumentVerified.tsx';
import { VerifyDocumentForm } from '#admin/component/VerifyDocumentForm.tsx';
import type { VerificationResult } from '#admin/hook/useVerifyDocument.ts';

export const VerifyDocument: React.FC = () => {
  const [verificationResult, setVerificationResult] = React.useState<VerificationResult | null>(null);

  const handleBack = () => {
    setVerificationResult(null);
  };

  return (
    <>
      <header className="fixed top-0 left-0 z-10 flex h-[65px] w-screen justify-center border-b bg-blue py-3">
        <nav className="container flex">
          <Link className="contents" to={'/'}>
            <img className="mx-5 max-h-[42px]" src="/atlas-path-nav-logo.svg" />
          </Link>
        </nav>
      </header>

      <main className="flex flex-col gap-6 px-[7.5rem] pt-10 pb-[6.25rem]">
        <h4 className="h4 text-grey-body">Verify Document</h4>
        <div className="flex flex-col items-center justify-center rounded-2xl bg-white py-12">
          {verificationResult?.status === 'verified' && <DocumentVerified verificationResult={verificationResult} onBack={handleBack} />}
          {verificationResult && ['not-verified', 'error'].includes(verificationResult.status) && (
            <DocumentNotVerified verificationResult={verificationResult} onBack={handleBack} />
          )}
          {!verificationResult && <VerifyDocumentForm onVerify={setVerificationResult} title="Upload a file to verify the authenticity of a document." />}
        </div>
      </main>
    </>
  );
};
