import cx from 'clsx';
import type React from 'react';
import type { IconType } from 'react-icons';
import { Link } from 'react-router-dom';
import { Button } from 'ui/component/Button.tsx';
import { IArrowForward, IInfo } from 'ui/component/Icons.tsx';
import { Tag } from 'ui/component/Tag.tsx';
import { Tooltip } from 'ui/component/Tooltip.tsx';

type Props = {
  className?: string;
  title: string;
  value: number;
  comparisonValue?: React.ReactNode;
  description?: string;
  Icon?: IconType;
  color?: string;
  targetURL?: string;
  targetName?: string;
};

export const KPI: React.FC<Props> = ({ className, title, description, value, comparisonValue, targetName, targetURL, Icon, color = 'gray' }: Props) => {
  return (
    <div className={cx('flex flex-col items-start justify-items-center gap-4 rounded-xl bg-white p-6', className)}>
      {Icon && <Tag className={cx(color, '!border-0')}>{<Icon size={24} />}</Tag>}
      <h3 className={cx(color, 'flex items-center justify-center gap-2')}>
        <span>{title}</span>
        {description && (
          <Tooltip text={description}>
            <IInfo size={16} />
          </Tooltip>
        )}
      </h3>
      <span className="flex h-[70px] flex-row flex-wrap content-center gap-3 font-bold text-[64px]">
        {value} {comparisonValue && <span className="mt-1.5 text-[32px]">{comparisonValue}</span>}
      </span>
      {targetURL && targetName && (
        <Link to={targetURL}>
          <Button className="blue-outlined uppercase" RightIcon={IArrowForward}>
            {targetName}
          </Button>
        </Link>
      )}
    </div>
  );
};
